import type { RegistrationDataModel, RegistrationFlags } from '@/types';

export const defaultRegistrationFlags: RegistrationFlags = {
  emailIsValid: false,
  codeEmailed: false,

  emailCodeIsValid: false,
  registrationIsActive: false,

  formIsValid: false,
  formEmailed: false,

  usageRulesAccepted: false,

  phoneIsValid: false,
  codeSentToPhone: false,
  phoneCodeIsValid: false,
  phoneSaved: false,

  inviteEmailIsValid: false,

  avatarIsUploaded: false,
  userIdsIsSelected: false,
  groupIdsIsSelected: false,
};

export const defaultRegistrationData: RegistrationDataModel = {
  id: null,
  hash: null,
  fullName: '',
  password: '',
  confirmPassword: '',
  position: '',
  subDivisionTitle: '',
  userEmployeeInfoId: 0,
  checkSettingsId: 0,
  email: null,
};

export const defaultRegistrationPhone: {
  number: string;
  confirmationId: null | number;
  confirmationCode: string | null;
} = {
  number: '',
  confirmationId: null,
  confirmationCode: null,
};
