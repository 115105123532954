import type { OverlayEventDetail } from '@ionic/core';
import type { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { ref } from 'vue';

import {
  ChainTypeEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
} from '@/enums';
import {
  useMeet,
  isNativeMobile,
  showDismissingToast,
  toLastModel,
  useNotifications,
} from '@/helpers';
import { useI18n } from '@/i18n';
import {
  useAppStore,
  useChatStore,
  useMeetStore,
  useMessengerStore,
  useUserStore,
} from '@/store';
import type {
  EditMessageModel,
  MessageChainEntity,
  MessageEntity,
  MessagesReadModel,
  UserTyping,
  WebSocketModel,
} from '@/types';

export type IUseWebSockets = {
  initWebSockets: (model: WebSocketModel | null) => Promise<void>;
  startWebSockets: () => Promise<boolean>;
  stopWebSockets: () => Promise<boolean>;
  typingEvents: any;
  isActive: boolean;
  connection?: HubConnection;
};

let webSocketsInstance: IUseWebSockets | null = null;

export const useWebSockets = (): IUseWebSockets => {
  if (webSocketsInstance) {
    return webSocketsInstance;
  }

  let connection: HubConnection | undefined;
  let isActive = false;
  const typingEvents = ref<UserTyping[]>([]);

  const _changeTyping = (data: UserTyping): void => {
    const index = typingEvents.value.findIndex(
      (f: UserTyping) => f.chainId === data.chainId && f.userId === data.userId
    );
    if (~index) {
      typingEvents.value.splice(index, 1, data);
    } else {
      typingEvents.value.push(data);
    }
  };

  const _onReconnected = async (
    connectionId?: string | undefined
  ): Promise<void> => {
    const meetStore = useMeetStore();
    const model = useAppStore().getWebSocketModel;
    if (!model) {
      console.error('Reconnection failed - model is null', model, connectionId);
      return;
    }

    console.log('Connection reconnected', connectionId); //! DEBUG

    if (!connection) {
      console.error('Reconnection failed - connection is null', connection);
      return;
    }

    const invokeModel = {
      coreId: model.coreId,
      companyRowId: model.companyRowId,
      userId: model.userId,
      userRowId: model.userRowId,
    };
    await connection.invoke('connect', invokeModel);

    meetStore.$patch((state) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.connectionId = connection!.connectionId;
    });
  };

  const _onTyping = (data: UserTyping): void => {
    console.log(`User ${data.userId} is typing - ${data.typing}`); //! DEBUG

    _changeTyping({
      userId: +data.userId,
      chainId: +data.chainId,
      typing: data.typing,
    });
  };

  const _onUserConnected = (userId: number): void => {
    const appStore = useAppStore();

    console.log(`User ${userId} connected`); //! DEBUG

    const index = appStore.onlineUsers.findIndex((u: number) => u === userId);
    if (index < 0) {
      appStore.userConnected(userId);
    }

    console.log(`Connected users: ${appStore.onlineUsers}`); //! DEBUG
  };

  const _onUserDisconnected = (userId: number): void => {
    const appStore = useAppStore();

    console.log(`User ${userId} disconnected`); //! DEBUG

    appStore.userDisconnected(userId);

    console.log(`Connected users: ${appStore.onlineUsers}`); //! DEBUG
  };

  const _onPushMessage = async (message: MessageEntity): Promise<void> => {
    const appStore = useAppStore();
    const messengerStore = useMessengerStore();
    const chatStore = useChatStore();
    const currentChainId = chatStore.getId;
    const currentUserId = useUserStore().getId;
    const model = appStore.getWebSocketModel;

    if (!model) {
      console.error('Pushing message failed - model is null', model);
      return;
    }

    const newMess = message.originalMessage as MessageEntity;
    if (model.userId === newMess.authorId) {
      console.log('Message from the current user, no need to update anything'); //! DEBUG
      return;
    }

    newMess.direction = MessageDirectionTypeEnum.Incoming;
    console.log(`User ${newMess.authorId} sent a message - `, newMess); //! DEBUG

    _changeTyping({
      userId: newMess.authorId,
      chainId: newMess.chainId,
      typing: false,
    });

    let existedChain = messengerStore.getChainById(newMess.chainId);
    // If chain is not currently in the local storage - trying to get it
    if (!existedChain) {
      await messengerStore.chainById(newMess.chainId);
    }
    // Trying to get chain again
    existedChain = messengerStore.getChainById(newMess.chainId);
    if (!existedChain) {
      console.error(
        'Pushing message failed - chain is null',
        existedChain,
        newMess.chainId
      );
      return;
    }
    // If message is in archive chain, change chain type to active
    if (existedChain && existedChain.chainType === ChainTypeEnum.Archive) {
      existedChain.chainType = ChainTypeEnum.Active;
    }

    const chainIsMuted = existedChain?.muted;
    const notificationsIsActive = appStore.localNotifications;

    // If message is incoming, chain is not current, chain is not muted and notifications are active - playing sound
    const shouldPlaySound =
      newMess.direction === MessageDirectionTypeEnum.Incoming &&
      (newMess.chainId !== currentChainId || newMess.chainId === null) &&
      !chainIsMuted &&
      notificationsIsActive;

    if (shouldPlaySound) {
      if (!isNativeMobile) {
        const audioFile = new Audio(appStore.getAppNotificationSoundPath);
        await audioFile.play();
      } else {
        await useNotifications().scheduleLocalNotification(newMess);
      }
    }

    //TODO: Waiting for API - https://gitlab.united-grid.com/intra/core/-/issues/668
    await chatStore.updateChain(existedChain, false);

    messengerStore.updateLastMessage(
      toLastModel(newMess),
      model.userId !== newMess.authorId
    );

    // If the message is in the current chain and the author is not the current user - reading the message
    if (
      newMess.chainId === currentChainId &&
      newMess.authorId !== currentUserId
    ) {
      chatStore.read(
        {
          authorId: newMess.authorId,
          chainId: newMess.chainId,
          messageId: newMess.id,
          status: MessageDeliveryStatusEnum.ReadAll,
          uniqueId: newMess.uniqueId,
        } as MessagesReadModel,
        false
      );
      await chatStore.markAsRead(newMess.id);
    }
  };

  const _onEditMessage = (message: MessageEntity): void => {
    const messengerStore = useMessengerStore();

    console.log('editMessage', message.id);

    const mess = message.originalMessage;
    if (mess !== null) {
      useChatStore().redact({
        chainId: mess.chainId,
        message: toLastModel(mess),
        fileInfos: mess.attachedFiles.data,
        uniqueId: mess.uniqueId,
      } as EditMessageModel);

      messengerStore.updateLastMessage(toLastModel(mess), false);
    }
  };

  const _onDeleteMessage = (messageId: number, chainId: number): void => {
    console.log('deleteMessage', messageId, chainId);
    const { t } = useI18n();
    const chatStore = useChatStore();
    const messengerStore = useMessengerStore();
    const currentChainId = chatStore.chain?.chainId;
    const lastMessage = chatStore.delete(messageId, currentChainId as number);

    const index = messengerStore.data.findIndex(
      (f: MessageChainEntity) => f.chainId === chainId
    );

    if (~index) {
      if (currentChainId === chainId) {
        messengerStore.updateLastMessage(toLastModel(lastMessage), false);
      } else if (messengerStore.data[index].lastMessage.id === messageId) {
        messengerStore.data[index].lastMessage.text = t(
          'messenger.chatPage.deleted'
        );
        messengerStore.data[index].lastMessage.status =
          MessageDeliveryStatusEnum.Deleted;
      }
    }
  };

  const _registerConnectionEvents = (): void => {
    if (!connection) {
      console.error(
        'Registration of events failed - connection is null',
        connection
      );
      return;
    }

    // Connection on closed
    connection.onclose((data: any) => {
      console.log('Connection closed', data); //! DEBUG
    });

    // Connection on reconnected
    connection.onreconnected(_onReconnected);

    // Connection on typing
    connection.on('typing', _onTyping);

    // Connection on users online
    connection.on('usersOnline', (data: number[]) => {
      useAppStore().usersOnline(data);
    });

    // Connection on user connected
    connection.on('userConnected', _onUserConnected);

    // Connection on user disconnected
    connection.on('userDisconnected', _onUserDisconnected);

    // Connection on pushMessage
    connection.on('pushMessage', _onPushMessage);

    connection.on('editMessage', _onEditMessage);

    connection.on('readMessage', (message: MessageEntity) => {
      // console.log('readMessage - message', message); //! DEBUG
      const chatStore = useChatStore();
      chatStore.read(
        {
          authorId: message.authorId,
          chainId: message.chainId,
          messageId: message.id,
          status: message.status,
          uniqueId: message.uniqueId,
        } as MessagesReadModel,
        false
      );
    });

    connection.on('deleteMessage', _onDeleteMessage);
  };

  const _retryConnection = async (appStore = useAppStore()): Promise<void> => {
    const { t } = useI18n();
    try {
      const toast = await showDismissingToast(
        t('messenger.errorConnect'),
        t('retry'),
        false
      );

      // User tries to reconnect
      toast.onDidDismiss().then(async (event: OverlayEventDetail) => {
        if (event.role === 'retry') {
          await stopWebSockets();
          await initWebSockets(appStore.getWebSocketModel);
        }
      });
    } catch (err) {
      console.error('Reconnection failed', err);
    }
  };

  const _invokeConnection = async (
    connection: HubConnection,
    model: WebSocketModel,
    appStore = useAppStore()
  ): Promise<void> => {
    const meetStore = useMeetStore();

    try {
      const connectModel = {
        coreId: model.coreId,
        companyRowId: model.companyRowId,
        userId: model.userId,
        userRowId: model.userRowId,
      };
      await connection.invoke('connect', connectModel);

      console.log('Successfully invoked method on the server'); //! DEBUG

      appStore.$patch((state) => {
        state.loading = false;
      });

      meetStore.$patch({
        connectionId: connection.connectionId,
      });
    } catch (err) {
      console.error('Failed to invoke method on the server: ' + err);
      appStore.$patch((state) => {
        state.loading = false;
      });
      // If the connection is not established, show a toast with the ability to reconnect
      await _retryConnection(appStore);
    }
  };

  const _startConnection = async (
    connection: HubConnection,
    appStore = useAppStore()
  ): Promise<boolean> => {
    try {
      appStore.$patch((state) => {
        state.loading = true;
        state.isWaitingForCompleteLogin = false;
      });

      await connection.start();
      console.log('SignalR connection started', connection.state); //! DEBUG
      return true;
    } catch (err) {
      console.error('SignalR connection error: ' + err);
      appStore.$patch((state) => {
        state.loading = false;
      });
      return false;
    }
  };

  const startWebSockets = async (): Promise<boolean> => {
    const appStore = useAppStore();
    let isConnectSignalR = false;
    console.log('Starting WebSockets'); //! DEBUG

    try {
      const model = appStore.getWebSocketModel;
      if (!model) {
        console.error(
          'Initialization of WebSockets failed - model is null',
          model
        );
        return false;
      }

      // If the connection is not initialized, initialize it
      if (!connection) {
        _registerConnectionEvents();
        if (!connection) {
          console.error(
            'Initialization of WebSockets failed - connection is null',
            connection
          );
          return false;
        }
      }

      // Setting WebSocket as active
      isActive = true;
      console.log('Current connection state: ', connection.state); //! DEBUG

      appStore.$patch((state) => {
        state.loading = false;
      });

      // Trying to start the connection
      isConnectSignalR = await _startConnection(connection, appStore);

      // Trying to connect to the server
      if (isConnectSignalR) {
        await _invokeConnection(connection, model);
      }
      return isConnectSignalR;
    } catch (err) {
      console.error('Starting WebSockets failed', err);
      return false;
    }
  };

  const _initSignal = async (model: WebSocketModel): Promise<void> => {
    // console.log('≥≥≥Configuring SignalR connection', model); //! DEBUG

    //?
    connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${model.webSocket}/chat`)
      .build();

    console.log('SignalR connection created', connection); //! DEBUG

    const useMeetHelper = useMeet();

    // ?
    _registerConnectionEvents();

    useMeetHelper.registerEventsVideo(connection);

    await startWebSockets();
  };

  const initWebSockets = async (
    model: WebSocketModel | null
  ): Promise<void> => {
    if (!model) {
      console.error(
        'Initialization of WebSockets failed - model is null',
        model
      );
      return;
    }

    console.log('Checking if any WS is active', connection, isActive); //! DEBUG
    // Stop the current connection if it exists
    if (connection) {
      console.log('Stopping the current connection', connection); //! DEBUG
      await connection.stop();
    }

    console.log('≥≥≥Initializing WebSockets', model); //! DEBUG
    await _initSignal(model);
  };

  const stopWebSockets = async (): Promise<boolean> => {
    isActive = false;
    try {
      if (!connection) {
        console.warn('Connection is already undefined, nothing to stop'); //! DEBUG
        return false;
      }
      console.log('Stopping WebSockets - ', connection); //! DEBUG

      await connection.stop();
      console.log('Successfully stopped WebSocket connection');
      return true;
    } catch (err) {
      console.error('Stopping WebSockets failed', err);
      return false;
    }
  };

  webSocketsInstance = {
    initWebSockets,
    startWebSockets,
    stopWebSockets,
    typingEvents,
    isActive,
    connection,
  };

  return webSocketsInstance;
};
