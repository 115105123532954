<template>
  <div class="idea">
    <div class="idea-block">
      <div class="icon">
        <div class="voices">
          <div>
            {{ likesData.count }} <br />
            <small>{{ $t('feed.votes', likesData.count) }}</small>
          </div>
        </div>
        <ion-badge color="medium">{{ $t('feed.idea.title') }}</ion-badge>
      </div>
      <div class="info">
        <feed-text
          :text-data="ideaData.title"
          style="font-weight: bold"
          :feed-flag="feedFlag"
        />
        <br />
        <template v-if="ideaData.status !== undefined">
          <ion-text v-html="$t('feed.idea.status') + ':'" />
          <div
            v-if="currentUserRoleId >= UserRoleEnum.LightModerator"
            class="active-status"
            @click.stop="
              !updateStatusIsLoading && !disableStatusUpdate
                ? updateStatus($event)
                : null
            "
          >
            <feed-text
              :text-data="getStatusText(ideaData.status)"
              :feed-flag="feedFlag"
            />
            <icons-provider
              :icon-props="{ width: '14', height: '14' }"
              :name="
                updateStatusIsLoading
                  ? AppIconsEnum.CircleAnim
                  : AppIconsEnum.PencilSquare
              "
            />
          </div>
          <feed-text
            v-else
            :text-data="ideaData.status"
            :feed-flag="feedFlag"
          />
          <br />
        </template>
        <ion-text v-html="$t('feed.idea.problem') + ':'" />
        <feed-text :text-data="ideaData.problem" :feed-flag="feedFlag" />
        <br />
        <ion-text v-html="$t('feed.idea.solution') + ':'" />
        <feed-text :text-data="ideaData.solution" :feed-flag="feedFlag" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonBadge, IonText } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { ref, computed } from 'vue';

import { FeedText, IconsProvider } from '@/components';
import { AppIconsEnum, IdeaStatusEnum, UserRoleEnum } from '@/enums';
import type { FeedFlagEnum } from '@/enums';
import { ideaStatusUpdatePopover } from '@/helpers';
import { useI18n } from '@/i18n';
import { useUserStore, usePostStore } from '@/store';
import type {
  PostIdeaModel,
  PostLikesModel,
  RequestIdeaUpdateStatusModel,
} from '@/types';

const props = defineProps({
  postId: {
    type: Number,
    required: true,
  },
  ideaData: {
    type: Object as PropType<PostIdeaModel>,
    required: true,
  },
  likesData: {
    type: Object as PropType<PostLikesModel>,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  disableStatusUpdate: {
    type: Boolean,
    default: () => false,
  },
});

const userStore = useUserStore();
const postStore = usePostStore();
const currentUserRoleId: ComputedRef<UserRoleEnum> = computed(
  () => userStore.current?.roleId ?? 0
);
const ideaData: ComputedRef<PostIdeaModel> = computed(() => props.ideaData);
const likesData: ComputedRef<PostLikesModel> = computed(() => props.likesData);

const updateStatusIsLoading = ref<boolean>(false);

const updateStatus = async (ev: Event) => {
  const result = await ideaStatusUpdatePopover(ev, ideaData.value?.status);
  if (result.data !== undefined) {
    updateStatusIsLoading.value = true;
    const statusData = {
      statusId: result.data,
      statusText: getStatusText(result.data),
      statusComment: '',
    } as RequestIdeaUpdateStatusModel;
    await postStore.ideaUpdateStatus(props.postId, statusData);
    updateStatusIsLoading.value = false;
  }
};
const { t } = useI18n();
const getStatusText = (status: IdeaStatusEnum) => {
  switch (status) {
    case IdeaStatusEnum.New:
      return t('feed.idea.statuses.new');

    case IdeaStatusEnum.InProgress:
      return t('feed.idea.statuses.progress');

    case IdeaStatusEnum.Realized:
      return t('feed.idea.statuses.realized');

    case IdeaStatusEnum.Archived:
      return t('feed.idea.statuses.archived');
  }
};
</script>
<style scoped lang="scss">
.idea {
  margin-top: 1rem;
}
.idea-block {
  position: relative;
  display: flex;
  align-items: center;
}
.idea-block .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.idea-block .voices {
  display: block;
  height: 64px;
  width: 64px;
  border: 1px solid var(--ion-color-medium);
  background-color: var(--ion-color-light-background-contrast);
  border-radius: 4px;
  margin-bottom: 0.1rem;
}
.idea-block .voices div {
  flex-direction: column;
  color: var(--ion-color-contrast);
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-inline: 0.2rem;
  font-size: 0.9rem;
}
.idea-block .icon ion-icon {
  font-size: 3rem;
}
.idea-block .info {
  margin-left: 1rem;
  font-size: 0.9rem;
}
.idea-block .info ion-text {
  font-weight: bold;
}
.idea-block ion-badge {
  border-radius: 4px;
}
.idea-block .active-status {
  display: flex;
  align-items: center;
}
.idea-block .active-status svg {
  margin-left: app-padding(sm);
}
.idea-block .active-status:hover {
  cursor: pointer !important;
  text-decoration: underline dotted;
}

@include respond-to-min-width(2xl) {
  .idea-block .voices div {
    font-size: 1rem;
  }
  .idea-block .info {
    font-size: 1rem;
  }
}
</style>
