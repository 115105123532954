import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseSuccessModel,
  EventChangeAnswerModel,
  RequestEventCreateModel,
  RequestEventEditModel,
  ResponseEventModel,
  RequestEventsByPeriodModel,
  ResponseEventsModel,
  ResponseEventAttendingModel,
  ResponseShortUsersModel,
} from '@/types';

export class EventApiService {
  async getEventsByPeriod(
    periodData: RequestEventsByPeriodModel
  ): Promise<ResponseEventsModel | ResponseErrorModel> {
    const groupId = periodData.groupId !== undefined ? periodData.groupId : '';
    return axios.get(
      `/events/list/?source=${periodData.source}&period=${periodData.period}&start=${periodData.start}&end=${periodData.end}&groupId=${groupId}`
    );
  }
  async eventCreate(
    eventData: RequestEventCreateModel
  ): Promise<ResponseEventModel | ResponseErrorModel> {
    return axios.post('/events/create', eventData);
  }
  async eventEdit(
    eventId: number,
    eventData: RequestEventEditModel
  ): Promise<ResponseEventModel | ResponseErrorModel> {
    return axios.post(`/events/update/${eventId}`, eventData);
  }
  async eventChangeAnswer(
    data: EventChangeAnswerModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/events/answer', {
      id: data.id,
      attending: data.attending,
    });
  }
  async getEventAttendings(
    id: number
  ): Promise<ResponseEventAttendingModel | ResponseErrorModel> {
    return axios.get(`/events/attendingsById/${id}`);
  }
  async eventDelete(
    eventId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/events/delete/${eventId}`);
  }
  async eventPlannedCreate(
    eventData: RequestEventCreateModel
  ): Promise<ResponseEventModel | ResponseErrorModel> {
    return axios.post('/events/planned/create', eventData);
  }
  async eventPlannedUpdate(
    postId: number,
    eventData: RequestEventCreateModel
  ): Promise<ResponseEventModel | ResponseErrorModel> {
    return axios.post(`/events/planned/${postId}/update`, eventData);
  }
  async eventPlannedForce(
    postId: number
  ): Promise<ResponseEventModel | ResponseErrorModel> {
    return axios.post(`/events/planned/${postId}/force`);
  }
  async eventPlannedDelete(
    postId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/events/planned/${postId}/delete`);
  }
  async getEventSubscribers(
    id: number
  ): Promise<ResponseShortUsersModel | ResponseErrorModel> {
    return axios.get(`/events/${id}/subscribers`);
  }
  async eventSubscribe(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/events/${id}/subscribe`);
  }
  async eventUnsubscribe(
    id: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/events/${id}/unsubscribe`);
  }
}
