<template>
  <div class="comments">
    <div v-if="commentsData.countRemains > 0" class="more-comments">
      <ion-button mode="md" fill="clear" @click.stop="loadMore()">
        {{ $t('feed.comments.loadMore', commentsData.countRemains) }}
      </ion-button>
    </div>
    <div
      v-else-if="
        commentsData.count > 2 &&
        commentsData.countRemains === 0 &&
        !showComments
      "
      class="more-comments"
    >
      <ion-button mode="md" fill="clear" @click.stop="loadMore()">
        {{ $t('customPage.showAll') }}
      </ion-button>
    </div>
    <div
      v-else-if="commentsData.countRemains === 0 && showComments"
      class="more-comments"
    >
      <ion-button mode="md" fill="clear" @click.stop="hideComments()">
        {{ $t('hide') }}
      </ion-button>
    </div>
    <feed-comments-item
      v-for="(comment, comment_index) in commentsToShow()"
      :key="`post_comment_${comment_index}`"
      :post-comment-data="comment"
      :current-user-id="currentUserId"
      :post-id="postId"
      :feed-flag="feedFlag"
      :feed-type="feedType"
      @click.stop
      @onCommentReply="commentReply"
    />
  </div>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';

import { FeedCommentsItem } from '@/components';
import type { FeedFilterTypeEnum, FeedFlagEnum } from '@/enums';
import { showToast } from '@/helpers';
import { useI18n } from '@/i18n';
import { usePostStore } from '@/store';
import type { CommentsDataModel, PostCommentsModel } from '@/types';

const { t } = useI18n();
const postStore = usePostStore();
const props = defineProps({
  commentsData: {
    type: Object as PropType<PostCommentsModel>,
    required: true,
  },
  postId: {
    type: Number,
    required: true,
  },
  currentUserId: {
    type: Number,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    required: true,
  },
});

// Refs
const showComments = ref(false);

// Computed
const commentsData: ComputedRef<PostCommentsModel> = computed(
  () => props.commentsData
);
const postId: ComputedRef<number> = computed(() => props.postId);

// Actions
const hideComments = () => {
  showComments.value = false;
};
const commentsToShow = () => {
  if (showComments.value) {
    return commentsData.value.data;
  }
  return commentsData.value.data.slice(0, 2);
};
const loadMore = async () => {
  showComments.value = true;
  const data = { postId: postId.value, url: commentsData.value.loadMoreUrl };
  if (!(await postStore.commentsLoadMore(data))) {
    await showToast(t('feed.comments.notLoad'), false);
  }
};

const commentReply = (comment: CommentsDataModel) => {
  emit('onCommentReply', comment);
};

// Emits
const emit = defineEmits(['onCommentReply']);
</script>
<style scoped lang="scss">
.comments {
  border-top: 1px solid var(--ion-color-light-custom);

  &:hover {
    cursor: default;
  }

  .comments-no-padding {
    padding-top: 0;
  }
}
.more-comments {
  display: flex;
  justify-content: center;

  ion-button {
    --color: var(--ion-color-medium);
    font-size: 0.7em;
  }
}
</style>
