import type { _ActionsTree, _GettersTree, StateTree, Store } from 'pinia';
import { createPinia } from 'pinia';
import PiniaLogger from 'pinia-logger';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import type { ErrorMessageModel } from '@/types';

export interface BaseState<T> {
  data: T | null;
  errors: ErrorMessageModel[];
  loading: boolean;
}

export interface EntityState<T> {
  data: T[];
  errors: ErrorMessageModel[];
  loading: boolean;
}

export const stores: Store<
  string,
  StateTree,
  _GettersTree<StateTree>,
  _ActionsTree
>[] = [];

export const pinia = createPinia()
  .use(
    PiniaLogger({
      showDuration: true,
      expanded: false,
      showStoreName: true,
      showTime: true,
      disabled: import.meta.env.VITE_APP_USE_LOGS !== 'true',
    })
  )
  .use(piniaPluginPersistedstate)
  .use(({ store }) => {
    stores.push(store);
  });

export const resetAllStores = (withAppStore: boolean): void => {
  let _stores;

  if (withAppStore) {
    _stores = stores;
  } else {
    _stores = stores.filter((f) => f.$id !== ('app' || 'user' || 'network'));
  }

  _stores.forEach((store) => {
    store.$reset();
  });
};

export const calculateAllStoresSize = (): number => {
  return stores.reduce((acc, store) => {
    return acc + JSON.stringify(store.$state).length;
  }, 0);
};

export * from './app.pinia';
export * from './chat.pinia';
export * from './meet.pinia';
export * from './messenger.pinia';
export * from './user.pinia';
export * from './file.pinia';
export * from './network.pinia';
export * from './post.pinia';
export * from './group.pinia';
export * from './notification.pinia';
export * from './doc.pinia';
export * from './page.pinia';
export * from './event.pinia';
export * from './topics.pinia';
export * from './search.pinia';
export * from './badge.pinia';
export * from './projects.pinia';
export * from './wiki.pinia';
export * from './office.pinia';
export * from './ai.pinia';
export * from './menu.pinia';
export * from './registration.pinia';
export * from './admin.pinia';
