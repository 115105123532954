<template>
  <ion-button
    v-if="!isPostCreation"
    mode="md"
    fill="outline"
    color="medium"
    class="header-btn"
    @click="changeProject($event)"
  >
    <span>
      {{
        project && project.id > 0
          ? project.title
          : $t('taskManagement.projects.selectProject')
      }}
    </span>
  </ion-button>

  <div v-else-if="selectedGroupId" class="post-creation">
    <ion-button
      :disabled="!selectedGroupId || !accessToRepickProject"
      mode="md"
      fill="outline"
      class="project-btn"
      @click="changeProject($event)"
    >
      <icons-provider
        v-if="!project || project.id === 0"
        slot="start"
        :icon-props="{
          width: '16',
          height: '16',
          fill: 'var(--ion-color-medium)',
        }"
        name="severity-info"
      />
      <ion-label>
        {{
          project && project.id > 0
            ? project.title
            : $t('taskManagement.projects.selectProject')
        }}
      </ion-label>
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { onUnmounted, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import { IconsProvider } from '@/components';
import { useTaskManagement } from '@/helpers';
import { ROUTES_NAME } from '@/router';
import { useProjectsStore } from '@/store';
import type { ProjectEntity } from '@/types';

const props = defineProps({
  withRouting: {
    type: Boolean,
    required: true,
  },
  isPostCreation: {
    type: Boolean,
    required: true,
  },
  selectedGroupId: {
    type: undefined as unknown as PropType<number | undefined>,
    default: undefined,
  },
});

const projectsStore = useProjectsStore();
const taskManagementHelper = useTaskManagement();

// Последний проект
const currentProject: ComputedRef<ProjectEntity> = computed(
  () => projectsStore.getCurrentProject
);

// Выбранный проект для создания новой задачи ( паблишер)
const selectedProject: ComputedRef<ProjectEntity | null> = computed(
  () => projectsStore.selectedProjectToCreateTask
);

// Выбираем с каким типом выше работаем
const project: ComputedRef<ProjectEntity | null> = computed(() =>
  !props.isPostCreation ? currentProject.value : selectedProject.value
);

const selectedGroupId: ComputedRef<number | undefined> = computed(
  () => props.selectedGroupId
);

const accessToRepickProject: ComputedRef<boolean> = computed(() => {
  if (route.name === ROUTES_NAME.PROJECT_BY_ID) {
    return false;
  } else {
    return true;
  }
});

const changeProject = async (ev: Event) => {
  await taskManagementHelper.taskManagementProjectSwitchHelper(
    ev,
    props.isPostCreation,
    props.withRouting,
    false,
    props.selectedGroupId
  );
};

const route = useRoute();
onMounted(() => {
  if (
    route.name !== ROUTES_NAME.PROJECT_BY_ID &&
    route.name !== ROUTES_NAME.GROUP_BY_ID
  ) {
    projectsStore.$patch({
      selectedProjectToCreateTask: null,
    });
  } else {
    projectsStore.$patch({
      selectedProjectToCreateTask: currentProject.value,
    });
  }
});

onUnmounted(() => {
  projectsStore.$patch({
    selectedProjectToCreateTask: null,
  });
});

watch(selectedGroupId, () => {
  if (route.name !== ROUTES_NAME.PROJECT_BY_ID) {
    projectsStore.$patch({
      selectedProjectToCreateTask: null,
    });
  }
});
</script>

<style scoped lang="scss">
.header-btn {
  margin: 0;
  height: 100%;
  text-transform: unset;
  --box-shadow: none;
  --border-width: 1px;
  max-width: 120px;
  --border-radius: #{app-radius(md)};
  font-size: 0.9rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.post-creation {
  display: flex;
  align-items: center;
  margin-left: app-padding(md);
  position: relative;
  min-width: 60px;
  flex-shrink: 1;
  height: 100%;

  ion-button {
    @include resetStyleFromIonicButton;
    height: 100%;
    max-width: 240px;
    font-size: 0.9rem;
    --border-color: var(--ion-color-custom-element-lighter);
    --color: var(--ion-color-medium);
    --background-hover: var(--ion-color-custom-element-lighter);
    --border-radius: #{app-radius(md)};
  }

  .project-btn {
    width: 100%;
    ion-label {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
    svg {
      margin-right: app-padding(sm);
    }
  }
}

@include respond-to-max-width(md) {
  .header-btn {
    max-width: 120px;
  }
}

@include respond-to-min-width(2xl) {
  .header-btn {
    font-size: 1rem;
  }
  .post-creation {
    &-btn {
      font-size: 1rem;
    }
  }
}
</style>
