<template>
  <div class="users">
    <ion-text @click.stop>
      <a
        v-for="(item, index) in usersData"
        :key="`user_${index}`"
        @click.stop="onChoose(item.id)"
      >
        {{ '@' + item.fullName + ' ' }}
      </a>
    </ion-text>
  </div>
</template>

<script lang="ts" setup>
import { IonText } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { ROUTES_NAME } from '@/router';
import type { UserShortModel } from '@/types';

const props = defineProps({
  usersData: {
    type: Object as PropType<UserShortModel[]>,
    required: true,
  },
});
const router = useRouter();
const onChoose = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.USER_BY_ID,
    params: { id: id },
  });
};

const usersData: ComputedRef<UserShortModel[]> = computed(
  () => props.usersData
);
</script>
<style scoped lang="scss">
.users {
  margin-top: 1rem;
}
a {
  text-decoration: none;
}
a:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>
