<template>
  <ion-app ref="pageRef">
    <keep-alive v-if="!isLoadingI18n">
      <component :is="layout" />
    </keep-alive>
    <app-loading v-else></app-loading>
  </ion-app>
</template>

<script lang="ts" setup>
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  IonApp,
  isPlatform,
  toastController,
  modalController,
  popoverController,
  alertController,
} from '@ionic/vue';
import { useIntervalFn, useWindowSize, watchDebounced } from '@vueuse/core';
import { informationCircle } from 'ionicons/icons';
import { isEqual } from 'lodash';
import type { ComponentPublicInstance, ShallowRef, ComputedRef } from 'vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { AppLoading } from '@/components';
import {
  AppAlertTypeEnum,
  AppLoadingTypeEnum,
  PostsModeEnum,
  RequirementsEnum,
  CompanyMenuEnum,
} from '@/enums';
import {
  isNativeMobile,
  useWebSockets,
  useTheme,
  useUserFlow,
  updateApp,
  feedTypeHelper,
  isWebMobile,
  useTokenHelper,
  showToast,
  clearStorage,
  componentTaskManagementTaskModal,
  changeFavicon,
  useLayoutHelper,
  useLoadingHelper,
  useRequirementsHelper,
  useAiAssistant,
  useMenu,
  useNotifications,
  // performAutoLogin,
} from '@/helpers';
import { useI18n, loadLocaleMessages } from '@/i18n';
import { AppLayoutDefault, AppLayoutMenu } from '@/layouts';
import { ROUTES_NAME } from '@/router';
import { getAppVersion } from '@/services';
import {
  useAppStore,
  useFileStore,
  useMeetStore,
  useMessengerStore,
  useNetworkStore,
  usePostStore,
  useMenuStore,
  useBadgesStore,
  useAiAssistantStore,
} from '@/store';

// Store
const appStore = useAppStore();
const fileStore = useFileStore();
const networkStore = useNetworkStore();
const meetStore = useMeetStore();
const messengerStore = useMessengerStore();
const postStore = usePostStore();
const menuStore = useMenuStore();
const badgeStore = useBadgesStore();
const aiAssistantStore = useAiAssistantStore();

// Router
const route = useRoute();
const router = useRouter();

// Other
const i18n = useI18n();
const { width: innerWidth } = useWindowSize();
const aiAssistantHelper = useAiAssistant();

// Refs
const pageRef = ref<ComponentPublicInstance | null>(null);
const isLoadingI18n = ref(!isNativeMobile);
const keyboardIsShow = ref<boolean>(false);

// Computed
const keyboard: ComputedRef<boolean> = computed(() => keyboardIsShow.value);
const postsToMarkAsRead: ComputedRef<number[]> = computed(
  () => postStore.postsToMarkAsRead
);
const postsMode: ComputedRef<PostsModeEnum | null> = computed(
  () => postStore.postsMode
);
const badgesForFetching: ComputedRef<number[]> = computed(
  () => badgeStore.badgesRequests.forFetching
);
const layout: ShallowRef = computed(() => {
  switch (route.meta.layout) {
    case 'auth':
      return AppLayoutDefault;
    default:
      return AppLayoutMenu;
  }
});
const isLoading: ComputedRef<boolean> = computed(
  () =>
    appStore.loading ||
    networkStore.loading ||
    messengerStore.loading ||
    (isPlatform('desktop') && menuStore.loading)
);
const isWaitingForCompleteLogin: ComputedRef<boolean> = computed(
  () => appStore.isWaitingForCompleteLogin
);
const isSettingNetwork: ComputedRef<boolean> = computed(
  () => networkStore.settingNetwork
);

// Actions
const handleClearStorage = async (version: string): Promise<void> => {
  const isDev = import.meta.env.DEV;
  await useLoadingHelper().create(i18n.t('loading'));

  try {
    await Promise.race([
      clearStorage(),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout')), 30000)
      ),
    ]);

    isDev &&
      (await showToast(
        i18n.t('successfulAppVersionUpdate', { version }),
        true
      ));
  } catch (error: any) {
    console.error('Error occurred:', error);
    await showToast(i18n.t('failedAppVersionUpdate', { version }), false);
  } finally {
    await useLoadingHelper().dismiss();
  }
};

const handleWheelClick = (e: MouseEvent): void => {
  if (e.button === 1) {
    // console.log('≥≥≥Middle button clicked.'); //! DEBUG
    appStore.$patch((state) => {
      state.isMiddleButtonClicked = true;
    });
    e.preventDefault();
    e.stopPropagation(); // Stop the event from propagating further
    const leftClickEvent = new MouseEvent('click', {
      bubbles: true,
      button: 0,
      buttons: 1,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      clientX: e.clientX,
      clientY: e.clientY,
      relatedTarget: e.relatedTarget,
    });

    if (!e.target) {
      console.error('Error during wheel click handling: no target found.', e);
      return;
    }

    e.target.dispatchEvent(leftClickEvent);
    // console.log('≥≥≥Simulating left button click instead.'); //! DEBUG
  }
};

// Checking if the app version has changed, if so proceed to reset the storage
const _checkVersionChange = async (): Promise<void> => {
  // Electron
  if (isPlatform('electron')) {
    const urlNewVersion = await getAppVersion();
    if (urlNewVersion.length > 0) {
      if (confirm(urlNewVersion)) {
        // * https://ionicframework.com/docs/updating/8-0#toast
        const toast = await toastController.create({
          duration: 0,
          message: `<a href="${urlNewVersion}" target="_blank">Download</a>`,
          icon: informationCircle,
        });
        await toast.present();
      }
    }
    return;
  }

  // Native mobile
  // eslint-disable-next-line no-undef
  const version = APP_VERSION;
  console.log(`Current version: ${version}`);
  // eslint-disable-next-line no-undef
  const build = APP_BUILD;
  console.log(`Current build: ${build}`);

  if (isNativeMobile) {
    const localVersion = await Preferences.get({ key: 'appVersion' });
    if (localVersion.value === null) {
      await Preferences.set({ key: 'appVersion', value: version });
    } else if (localVersion.value !== version) {
      console.log('App version changed, resetting stores...');

      await router.push({ name: ROUTES_NAME.FEED });
      await handleClearStorage(version);

      await Preferences.set({ key: 'appVersion', value: version });
    }
    return;
  }

  // Web
  if (!appStore.appVersion) {
    appStore.$patch({
      appVersion: version,
    });
  } else if (appStore.appVersion !== version) {
    console.log('App version changed, resetting stores...');

    await router.push({ name: ROUTES_NAME.FEED });
    await handleClearStorage(version);

    appStore.$patch({
      appVersion: version,
    });
  }
  return;
};

const _initTheme = async (): Promise<void> => {
  const userTheme = appStore.getLocalTheme;
  useTheme().initTheme(userTheme);

  const currentSettings = networkStore.settings;
  const color = useTheme().getAppColor(currentSettings?.headBackgroundColor);
  await useTheme().setTheme(color);

  if (appStore.clientSecret) {
    const settings = await networkStore.getSettings(appStore.clientSecret);
    if (settings !== undefined) {
      if (!isEqual(currentSettings, settings)) {
        const color = useTheme().getAppColor(settings?.headBackgroundColor);
        await useTheme().setTheme(color);
      }
      changeFavicon();
    }
  }
};

const _initLocales = async (): Promise<void> => {
  const localLanguage = appStore.locale;
  await loadLocaleMessages(i18n, localLanguage, undefined);

  if (appStore.clientSecret) {
    const networkStore = useNetworkStore();
    const currentNetworkId: ComputedRef<string> = computed(
      () => networkStore.getNetwork.id
    );
    const isBKGNetwork: ComputedRef<boolean> = computed(
      () =>
        useMenu().companiesList.value[CompanyMenuEnum.BKG] ===
        currentNetworkId.value
    );
    const currentResources = appStore.companyResources;
    const companyResources = await appStore.getCompanyResources();
    if (companyResources !== undefined) {
      if (!isEqual(currentResources, companyResources)) {
        await loadLocaleMessages(i18n, localLanguage, companyResources);
      }
    }
    if (isBKGNetwork.value) {
      await loadLocaleMessages(
        i18n,
        localLanguage,
        undefined,
        isBKGNetwork.value
      );
    }
  }
};

const _initInterface = async (): Promise<void> => {
  const localInterface = appStore.getLocalInterfaceSize;
  await useTheme().initInterface(localInterface);
};

const _onAuthorizedMount = async (): Promise<void> => {
  if (pageRef.value !== null && route.path === '/') {
    await useNotifications().initPushNotifications();
    await useUserFlow().setupApp();
  } else {
    await Promise.all([
      messengerStore.updateUnreadCounter(),
      useWebSockets().initWebSockets(appStore.getWebSocketModel),
    ]);
  }
  await useNotifications().initLocalNotifications();
  await useRequirementsHelper().check(RequirementsEnum.All);
};

const _onUnauthorizedMount = async (): Promise<void> => {
  networkStore.$reset();
  if (
    route.name !== ROUTES_NAME.LOGIN &&
    route.name !== ROUTES_NAME.REGISTRATION &&
    route.name !== ROUTES_NAME.ACTIVATION
  ) {
    await router.replace({ name: ROUTES_NAME.LOGIN });
  }
};

const _initNativeKeyboardListeners = async (): Promise<void> => {
  if (isNativeMobile) {
    if (Keyboard) {
      Keyboard?.addListener('keyboardDidShow', () => {
        keyboardIsShow.value = true;
        appStore.setKeyboardShown(true);
      });

      Keyboard?.addListener('keyboardDidHide', () => {
        keyboardIsShow.value = false;
        appStore.setKeyboardShown(false);
      });
    }
  }
};

const onMountedApp = async (): Promise<void> => {
  // Set the innerWidth value
  useLayoutHelper().updateInnerWidth(innerWidth.value);

  // Init the file store
  await fileStore.init();

  // Theme
  await _initTheme();

  // Locales
  await _initLocales();

  // Interface
  await _initInterface();

  // Native keyboard listeners
  await _initNativeKeyboardListeners();

  // Show bottom menu if it is a mobile device and the keyboard is not shown
  appStore.$patch((state) => {
    state.appBottomMenu =
      (isWebMobile || isNativeMobile) && !keyboardIsShow.value;
  });

  // Set as active in messenger store
  messengerStore.$patch((state) => {
    state.isActive = true;
  });

  // If native mobile, hide the splash screen and update the app
  if (isNativeMobile) {
    await SplashScreen.hide();
    await updateApp();
  } else {
    isLoadingI18n.value = false;
  }

  // Check if the user is authorized
  if (appStore.isAuth) {
    await _onAuthorizedMount();
  } else {
    await _onUnauthorizedMount();
  }

  // Version change check
  await _checkVersionChange();

  // Clear timer id in store and set new custom menu timer
  useUserFlow().clearCustomMenuTimer();
  useUserFlow().setCustomMenuTimer();
};

App.addListener('appStateChange', async ({ isActive }) => {
  console.log('App state changed. Is active?', isActive);

  if (!isActive) {
    if (isNativeMobile) {
      await useWebSockets().stopWebSockets();
    }
    return;
  }

  await _checkVersionChange();

  const { token } = await useTokenHelper().getNewToken(false);
  if (token) {
    if (isNativeMobile) {
      await useWebSockets().startWebSockets();
      await useNotifications().setBadgeCount();
      await updateApp();
    }
    await messengerStore.updateUnreadCounter();
  }
});

//?: Why here
meetStore.$reset();

// Watchers
watch(keyboard, () => {
  appStore.$patch({
    appBottomMenu: (isWebMobile || isNativeMobile) && !keyboardIsShow.value,
  });
});

watch(
  innerWidth,
  () => {
    useLayoutHelper().updateInnerWidth(innerWidth.value);
  },
  { immediate: true }
);

watch(route, async () => {
  //Закрываем все окна при роуте
  modalController.getTop().then((modal) => {
    return modal && modal?.id !== 'groupManage'
      ? modalController.dismiss()
      : null;
  });
  popoverController.getTop().then((popover) => {
    return popover ? popoverController.dismiss() : null;
  });
  alertController.getTop().then((alert) => {
    return alert && alert?.id !== AppAlertTypeEnum.UpdateApp
      ? alertController.dismiss()
      : null;
  });
});

watch(isLoading, async () => {
  // If it is the first login, then we do not show the loading
  // console.log('≥≥≥isWaitingForCompleteLogin = ', isWaitingForCompleteLogin.value); //! DEBUG
  if (isWaitingForCompleteLogin.value) return;

  if (!isLoading.value) {
    await useLoadingHelper().dismiss(AppLoadingTypeEnum.OtherLoading);
  } else {
    if (route.name === ROUTES_NAME.LOGIN) {
      await useLoadingHelper().create(
        i18n.t('loading'),
        AppLoadingTypeEnum.OtherLoading
      );
    }
  }
});

watch(isSettingNetwork, async () => {
  if (
    route.name === ROUTES_NAME.LOGIN ||
    route.name === ROUTES_NAME.REGISTRATION ||
    route.name === ROUTES_NAME.ACTIVATION
  )
    return;

  if (!isSettingNetwork.value) {
    await useLoadingHelper().dismiss(AppLoadingTypeEnum.NetworkSetting);
  } else {
    await useLoadingHelper().create(
      i18n.t('network.loading'),
      AppLoadingTypeEnum.NetworkSetting
    );
  }
});

//Bitoobit. Check do we have loaded posts.
//const posts: ComputedRef<PostEntity[]> = computed(() => postStore.data);

watch(postsMode, async (currentMode, lastMode) => {
  if (lastMode !== currentMode) {
    if (route.name === ROUTES_NAME.FEED) {
      if (postsMode.value === PostsModeEnum.Feed) {
        //Commented out the Bitoobit changes
        //if (posts.value?.length === 0) await feedTypeHelper(route.name);
        await feedTypeHelper(route.name);
      }
    }
  }
});

// Формирование ссылки на открытую задачу
const queryParams = computed(() => route.query);
watch(
  queryParams,
  async (newValue) => {
    const { showTaskId, projectId } = newValue;
    if (showTaskId && projectId) {
      const modal = await componentTaskManagementTaskModal(
        Number(showTaskId),
        Number(projectId)
      );

      await modal.onDidDismiss();

      await router.replace({ query: undefined });
    }
  },
  { deep: true, immediate: true }
);

//NOTE: Keeping track of the array of posts to mark as read, send the request 5 seconds after the last activity
watchDebounced(
  postsToMarkAsRead,
  async () => {
    if (postsToMarkAsRead.value.length > 0 && appStore.isAuth) {
      await postStore.markAsRead(false, postsToMarkAsRead.value);
    }
  },
  { debounce: 5000, maxWait: 120000 }
);
//NOTE: Keeping track of the array of posts to mark as read, change the status locally almost as soon as we see the post
watchDebounced(
  postsToMarkAsRead,
  async () => {
    if (postsToMarkAsRead.value.length > 0) {
      postStore.markAsReadLocally(postsToMarkAsRead.value);
    }
  },
  { debounce: 1500, maxWait: 2000 }
);

//NOTE: Tracking the array of badges to be requested from the server, send the request 2 seconds after the last activity
watchDebounced(
  badgesForFetching,
  async () => {
    if (!appStore.clientSecret) {
      return;
    }

    if (badgesForFetching.value.length > 0) {
      badgeStore.$patch((state) => {
        state.badgesRequests.activeRequests = [
          ...state.badgesRequests.activeRequests,
          ...badgesForFetching.value,
        ];
      });

      await badgeStore.badgesByIds(badgesForFetching.value);

      badgeStore.$patch((state) => {
        state.badgesRequests.forFetching = [];
        state.badgesRequests.activeRequests = [];
      });
    }
  },
  { debounce: 2000, maxWait: 3000 }
);

useIntervalFn(async () => {
  await useRequirementsHelper().check(RequirementsEnum.All);
}, 900000);

// Lifecycle
onMounted(async () => {
  // console.log('≥≥≥App mounted.'); //! DEBUG
  try {
    // Custom event listener for the middle mouse button
    window.addEventListener('mouseup', handleWheelClick);

    await onMountedApp();
  } catch (e: any) {
    console.error('Error during onMountedApp:', e);
  }
});

// onUnmounted(async () => {
onUnmounted(async () => {
  // console.log('≥≥≥App unmounted.'); //! DEBUG
  try {
    const currentAssistant = aiAssistantStore.assistant;
    if (currentAssistant) {
      await aiAssistantHelper.deleteAssistant(currentAssistant);
    }
    await useWebSockets().stopWebSockets();
    App.removeAllListeners();
    window.removeEventListener('mouseup', handleWheelClick);
    // clearInterval(timer);
  } catch (e: any) {
    console.error('Error during onUnmounted:', e);
  }
});
</script>
