import { cloneDeep } from 'lodash';

import { defaultShortGroup } from './group';

import {
  TaskManagementActiveSortTypeEnum,
  TaskManagementMilestonesSortByEnum,
  TaskManagementProjectsSortByEnum,
  TaskManagementSortDirectionEnum,
  TaskManagementTasksSortByEnum,
} from '@/enums';
import { getIsoNow } from '@/helpers';
import type {
  ProjectEntity,
  TaskManagementDragCardModel,
  TasksIdsModel,
  TasksIdsProjectModel,
  TasksIdsDataModel,
  MilestonesIdsModel,
  MilestonesIdsProjectModel,
  MilestonesIdsDataModel,
  ProjectsIdsModel,
  ProjectIdsDataModel,
  TaskManagementMainHeaderTasksSearchModel,
  TaskManagementMainHeaderMilestonesSearchModel,
  ProjectIdsGroupModel,
  TasksIdsColumnModel,
  UserShortModel,
  TasksIdsAuthorModel,
  TasksIdsAssigneeModel,
  TaskManagementSortingModel,
} from '@/types';

export const defaultTaskManagementBoardModel = {
  id: 0,
  projectId: 0,
  title: '',
  dateCreated: getIsoNow(),
  showOpened: true,
  showClosed: true,
  columns: [],
};

export const defaultTaskManagementProject: ProjectEntity = {
  id: 0,
  title: '',
  author: {} as UserShortModel,
  columns: [],
  milestonesIds: [],
  group: cloneDeep(defaultShortGroup),
  companyId: 0,
  currentMilestoneId: 0,
  dateCreated: '',
  isDeleted: false,
  description: '',
  milestones: { count: 0, closedCount: 0 },
  tasks: { count: 0, closedCount: 0 },
  board: defaultTaskManagementBoardModel,
};

export const defaultTaskManagementDragCardModel: TaskManagementDragCardModel = {
  assignee: null,
  author: null,
  comments: { count: 0, data: [] },
  columnIndex: null,
  dateArchived: '',
  dateClosed: '',
  dateCreated: '',
  dateDue: '',
  dateLastUpdated: '',
  description: '',
  files: { count: 0, data: [] },
  height: 0,
  history: [],
  id: 0,
  identifier: 0,
  isArchived: false,
  isClosed: false,
  links: { count: 0, data: [] },
  milestone: null,
  modifier: null,
  participants: { count: 0, data: [] },
  projectId: 0,
  tags: { count: 0, data: [] },
  title: 'Plug',
  wikis: { count: 0, data: [] },
  type: 'Plug',
  notify: null,
};

export const defaultTaskManagementTasksIdsModel: TasksIdsModel = {
  projects: [] as TasksIdsProjectModel[],
  search: { ids: [], loadMoreUrl: null } as TasksIdsDataModel,
  columns: [] as TasksIdsColumnModel[],
  authors: [] as TasksIdsAuthorModel[],
  assignees: [] as TasksIdsAssigneeModel[],
};

export const defaultTaskManagementMilestonesIdsModel: MilestonesIdsModel = {
  projects: [] as MilestonesIdsProjectModel[],
  search: { ids: [], loadMoreUrl: null } as MilestonesIdsDataModel,
};

export const defaultTaskManagementProjectsIdsModel: ProjectsIdsModel = {
  all: { ids: [], loadMoreUrl: null } as ProjectIdsDataModel,
  search: { ids: [], loadMoreUrl: null } as ProjectIdsDataModel,
  groups: [] as ProjectIdsGroupModel[],
  currentId: 0,
};

export const defaultTaskManagementTasksSearchModel: TaskManagementMainHeaderTasksSearchModel =
  {
    searchQuery: '',
    author: null,
    assignee: null,
    tag: null,
    milestone: null,
  };

export const defaultTaskManagementMilestonesSearchModel: TaskManagementMainHeaderMilestonesSearchModel =
  {
    searchQuery: '',
  };

export const defaultTaskManagementSortingModel: TaskManagementSortingModel = {
  activeSortType: TaskManagementActiveSortTypeEnum.Projects,
  tasks: {
    sortBy: TaskManagementTasksSortByEnum.Identifier,
    direction: TaskManagementSortDirectionEnum.Desc,
  },
  milestones: {
    sortBy: TaskManagementMilestonesSortByEnum.Title,
    direction: TaskManagementSortDirectionEnum.Asc,
  },
  projects: {
    sortBy: TaskManagementProjectsSortByEnum.Title,
    direction: TaskManagementSortDirectionEnum.Asc,
  },
};
