<template>
  <docs-item-view
    :id="wiki.id"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.Wiki"
    :name="wikiName"
    :created-at="wiki.createdAt"
    :author="wikiAuthor"
    :edited-at="wiki.editedAt"
    :editor="wikiEditor"
    :group="wikiGroupTitle"
    :icon="icons.documentText"
    @onMenuOpen="openWikiMenu"
    @onItemClick="openWiki"
  />
</template>

<script lang="ts" setup>
import { documentTextOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { DocsItemView } from '@/components';
import { DocumentTypeEnum } from '@/enums';
import type { DocsViewTypeEnum, WikiMenuActionEnum } from '@/enums';
import { docBrowserContextMenu, useWiki } from '@/helpers';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';
import type { DocEntity, WikiModel } from '@/types';

const icons = {
  documentText: documentTextOutline,
};
const props = defineProps({
  wiki: {
    type: Object as PropType<WikiModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

const docStore = useDocStore();

const isAttachment: ComputedRef<boolean> = computed(
  () => docStore.isAttachmentModal
);
const selectedDocs: ComputedRef<DocEntity[]> = computed(
  () => docStore.selectedDocs
);

const { t } = useI18n();
const wikiGroupTitle: ComputedRef<string> = computed(() =>
  props.wiki.group ? props.wiki.group.title : t('network.allNetwork')
);
const wikiName: ComputedRef<string> = computed(() => props.wiki.name);
const wikiAuthor: ComputedRef<string> = computed(() =>
  props.wiki.author ? props.wiki.author?.fullName : ''
);
const wikiEditor: ComputedRef<string> = computed(() =>
  props.wiki.modifier ? props.wiki.modifier?.fullName : ''
);

const emit = defineEmits(['onWikiOpen', 'onWikiPreview']);

const openWiki = async (throughMenu?: boolean) => {
  if (isAttachment.value) {
    if (!selectedDocs.value.some((n) => n.data.id === props.wiki.id)) {
      docStore.$patch({
        selectedDocs: [
          ...selectedDocs.value,
          { documentType: DocumentTypeEnum.Wiki, data: props.wiki },
        ],
      });
    } else {
      docStore.$patch({
        selectedDocs: selectedDocs.value.filter(
          (n) => n.data.id !== props.wiki.id
        ),
      });
    }
  } else {
    if (throughMenu) {
      emit('onWikiPreview', props.wiki.id);
    } else {
      emit('onWikiOpen', props.wiki.id);
    }
  }
};

const openWikiMenu = async (ev: Event) => {
  const result = await docBrowserContextMenu(ev, {
    documentType: DocumentTypeEnum.Wiki,
    data: props.wiki,
  });
  if (result.data) {
    const data = result.data as WikiMenuActionEnum;
    await useWiki().whichActionToMake(
      {
        action: data,
        wiki: props.wiki,
      },
      true
    );
  }
};
</script>
