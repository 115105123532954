<template>
  <component
    :is="component.name"
    v-bind="component.props"
    v-on="component.events"
  />
</template>

<script lang="ts" setup>
import type { Component, ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import {
  DocsItemFolder,
  DocsItemWiki,
  DocsItemFile,
  DocsItemExternalLink,
} from '@/components';
import { DocumentTypeEnum } from '@/enums';
import type { DocsViewTypeEnum } from '@/enums';
import type {
  DocEntity,
  FileModel,
  FolderModel,
  WidgetDocModel,
} from '@/types';

const props = defineProps({
  doc: {
    type: Object as PropType<DocEntity | WidgetDocModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
  searchView: {
    type: Boolean,
    default: () => false,
  },
  fileMenuIsDisabled: {
    type: Boolean,
    default: () => false,
  },
});

const component: ComputedRef<{
  name: Component | null;
  props: {};
  events: {};
}> = computed(() => {
  if (props.searchView) {
    return {
      name: DocsItemFile,
      props: {
        'view-type': props.viewType,
        'file-menu-is-disabled': props.fileMenuIsDisabled,
        file: props.doc,
      },
      events: {
        onFilePreview: onFilePreview,
        onImagePreview: onImagePreview,
        onLoading: onLoading,
      },
    };
  }

  switch (props.doc.documentType) {
    case DocumentTypeEnum.File:
      return {
        name: DocsItemFile,
        props: {
          'view-type': props.viewType,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          file: props.doc.data,
        },
        events: {
          onLoading: onLoading,
          onImagePreview: onImagePreview,
          onFilePreview: onFilePreview,
        },
      };

    case DocumentTypeEnum.Folder:
      return {
        name: DocsItemFolder,
        props: {
          'view-type': props.viewType,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          folder: props.doc.data,
        },
        events: { onFolderOpen: onFolderOpen },
      };

    case DocumentTypeEnum.Wiki:
      return {
        name: DocsItemWiki,
        props: {
          'view-type': props.viewType,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          wiki: props.doc.data,
        },
        events: { onWikiOpen: onWikiOpen, onWikiPreview: onWikiPreview },
      };

    case DocumentTypeEnum.ExternalLink:
      return {
        name: DocsItemExternalLink,
        props: {
          'view-type': props.viewType,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          link: props.doc.data,
        },
        events: {},
      };

    default:
      return {
        name: null,
        props: {},
        events: {},
      };
  }
});

const onLoading = (file: FileModel) => {
  emit('onLoading', false, file);
};

const onFolderOpen = (folder: FolderModel) => {
  emit('onFolderOpen', folder);
};

const onWikiOpen = (wikiId: number) => {
  emit('onWikiPreview', wikiId);
};
const onWikiPreview = (wikiId: number) => {
  emit('onWikiPreview', wikiId);
};

const onFilePreview = (file: FileModel) => {
  emit('onFilePreview', file);
};

const onImagePreview = (file: FileModel) => {
  emit('onImagePreview', file);
};

// const onVideoView = (file: FileModel) => {
//   emit('onVideoView', file);
// };

const emit = defineEmits([
  'onFolderOpen',
  'onWikiOpen',
  'onLoading',
  'onWikiPreview',
  'onFilePreview',
  'onImagePreview',
  // 'onVideoView',
]);
</script>

<style scoped lang="scss"></style>
