<template>
  <ion-list v-if="topics.length > 0" class="list">
    <ion-item
      v-for="(item, topicIndex) in topics"
      :key="`topic_${topicIndex}`"
      @click.stop="onChoose(item.label)"
    >
      <ion-label>
        <h2>{{ item.label }}</h2>
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script lang="ts" setup>
import { IonList, IonItem, IonLabel } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';

import { useTopicStore } from '@/store';
import type { TopicEntity } from '@/types';

const topicStore = useTopicStore();
const props = defineProps({
  caret: {
    type: Number,
    required: true,
    default: () => 0,
  },
  message: {
    type: String,
    required: true,
    default: () => '',
  },
});
const emit = defineEmits(['chooseTopic']);
const topics = ref<TopicEntity[] | []>([]);
let index = -1;

const caret: ComputedRef<number> = computed(() => props.caret);

watch(caret, async () => {
  const message =
    props.caret < props.message.length
      ? props.message.substring(0, props.caret)
      : props.message;
  index = message.lastIndexOf('#');
  if (~index) {
    if (
      message.substring(index + 1) !== '' &&
      !message.substring(index + 1).includes(' ')
    ) {
      topics.value = await topicStore.topicsAutocomplete(
        message.substring(index + 1)
      );
    } else {
      topics.value = [];
    }
  } else {
    topics.value = [];
  }
});

const onChoose = (alias: string) => {
  emit('chooseTopic', { alias, index });
};
</script>

<style scoped lang="scss">
.list {
  max-height: 210px;
  overflow: scroll;
  padding-inline: 1rem;
  margin: 0;
  border: 1px solid var(--ion-color-custom-element-lighter);
  border-radius: 0 0 app-radius(md) app-radius(md);
  border-top: none;
  position: relative;
  bottom: 0.5rem;
  z-index: 1;
  background: var(--ion-color-light-background-contrast);
}
.list::-webkit-scrollbar {
  width: 0;
}
ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --background: rgba(255, 255, 255, 0);
}
ion-item:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>
