import { assign, chain, cloneDeep, find } from 'lodash';
import { defineStore } from 'pinia';

import {
  ChainTypeEnum,
  MessageDeleteEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MessageTypeEnum,
} from '@/enums';
import { toEditModel, toLastModel, toShortUserModel } from '@/helpers';
import { $api } from '@/services';
import { useMessengerStore } from '@/store';
import type {
  DraftMessageModel,
  EditMessageModel,
  ErrorMessageModel,
  MediaModel,
  MessageChainEntity,
  MessageEntity,
  MessagesModel,
  MessagesReadModel,
  RequestChainModel,
  RequestMessageModel,
  ResponseChainModel,
  ResponseChatAvatarModel,
  ResponseErrorModel,
  ResponseMessageModel,
  ResponseMessagesModel,
  UserEntity,
  UserShortModel,
} from '@/types';

interface ChatState {
  chain: MessageChainEntity | null;
  quote: MessageEntity | null;
  isEdit: boolean;
  inAction: boolean;
  updated: number;
  callToken: string | null;
  disableLoadMore: boolean;
  errors: ErrorMessageModel[];
  loading: boolean;
  chats: {
    [chainId: number]: {
      data: MessageEntity[];
      loadMoreUrl: string | null;
    };
  };
}

export const useChatStore = defineStore({
  id: 'chat',
  state: (): ChatState => ({
    errors: [],
    loading: false,
    chain: null,
    quote: null,
    isEdit: false,
    inAction: false,
    updated: 0,
    callToken: null,
    disableLoadMore: false,
    chats: {},
  }),
  getters: {
    getId: (state): number => state.chain?.chainId ?? 0,
    getMessages:
      (state) =>
      (chainId: number): MessagesModel[] | null => {
        if (!state.chats[chainId]) {
          return null;
        }
        return chain(state.chats[chainId].data)
          .groupBy((message: MessageEntity) =>
            message.createdAt.substring(0, 10)
          )
          .map(
            (messages: MessageEntity[]) =>
              ({
                created: messages[0].createdAt,
                data: messages,
              }) as MessagesModel
          )
          .value();
      },
    getLoadMoreUrl: (state) => (chainId: number) =>
      state.chats[chainId]?.loadMoreUrl,
    getUsers(state): (text: string, userId: number) => UserShortModel[] {
      return (text: string, userId: number) => {
        text = text.toLowerCase();
        const users = state.chain?.users ?? [];
        return text.length > 0
          ? users.filter(
              (n: UserShortModel) =>
                (n.mainAlias.toLowerCase().includes(text) ||
                  n.fullName.toLowerCase().includes(text)) &&
                n.id !== userId
            )
          : users.filter((n: UserShortModel) => n.id !== userId);
      };
    },
    getUsersCnt(state): number {
      return state.chain?.users.length ?? 0;
    },
    getType(state): ChainTypeEnum {
      return state.chain?.chainType ?? ChainTypeEnum.Active;
    },
    autocomplete: (state) => (text: string) => {
      text = text.toLowerCase();
      if (state.chain !== null) {
        return text.length > 0
          ? state.chain.users.filter((n: UserShortModel) =>
              n.fullName.toLowerCase().includes(text)
            )
          : state.chain.users;
      } else {
        return [];
      }
    },
    autocompleteToAdmins: (state) => (text: string) => {
      text = text.toLowerCase();
      if (state.chain !== null) {
        return text.length > 0
          ? state.chain.users.filter(
              (n: UserShortModel) =>
                n.fullName.toLowerCase().includes(text) &&
                !state.chain?.adminsIds.some((m: number) => m == n.id)
            )
          : state.chain.users;
      } else {
        return [];
      }
    },
    getChainAdmins(state): UserShortModel[] {
      const chainAdmins = [] as UserShortModel[];
      if (state.chain !== null) {
        state.chain.adminsIds.forEach((element) => {
          const admin = find(state.chain?.users, { id: element });
          if (admin !== undefined) {
            chainAdmins.push(admin);
          }
        });
      }
      return chainAdmins;
    },
  },
  actions: {
    async byChainId(chainId: number): Promise<boolean> {
      const messengerStore = useMessengerStore();
      const entity = await getChain(this.chain, chainId);
      const chainEntity = entity as MessageChainEntity;
      if (chainEntity.chainId) {
        this.$patch({
          chain: chainEntity,
        });
        messengerStore.updateMessengerData(messengerStore.data, [chainEntity]);
      } else {
        this.errors = entity as ErrorMessageModel[];

        this.loading = false;
      }

      this.$patch({
        loading: true,
        errors: [],
        quote: null,
        isEdit: false,
      });
      const response = await $api.messenger.getMessagesByChainId(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        this.$patch({
          loading: false,
          chain: this.chain,
          chats: {
            [chainId]: {
              data: model.data,
              loadMoreUrl: model.loadMoreUrl,
            },
          },
          quote: null,
        });

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async byUserId(userId: number): Promise<boolean> {
      this.$patch({
        loading: true,
        errors: [],
        chain: null,
        quote: null,
        isEdit: false,
      });
      const response = await $api.messenger.getByUserId(userId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        const chainId = model.data[0].chainId;
        const entity = await getChain(this.chain, chainId);
        const chainEntity = entity as MessageChainEntity;
        if (chainEntity.chainId) {
          this.$patch({
            chain: chainEntity,
          });
        } else {
          this.errors = entity as ErrorMessageModel[];

          this.loading = false;
        }

        this.$patch({
          loading: false,
          chain: chainEntity,
          chats: {
            [chainId]: {
              data: model.data,
              loadMoreUrl: model.loadMoreUrl,
            },
          },
          quote: null,
          errors: [],
        });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    setDisableLoadMore(state: boolean) {
      this.disableLoadMore = state;
    },
    setChain(chain: MessageChainEntity | undefined) {
      if (!chain) {
        return;
      }
      this.$patch({
        chain,
      });
    },
    async updateChain(
      entity: MessageChainEntity,
      setAsCurrent: boolean
    ): Promise<void> {
      this.$patch((state) => {
        state.loading = setAsCurrent ? true : state.loading;
        state.errors = [];
        state.chain = setAsCurrent ? entity : this.chain;
        state.quote = null;
        state.isEdit = false;
      });

      const response = await $api.messenger.getMessagesByChainId(
        entity.chainId
      );

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        const lastMessage = model.data.at(-1);
        if (lastMessage) {
          this.upsert(lastMessage);
        }

        this.$patch((state) => {
          state.loading = false;
          state.chain = this.chain;
          state.chats = {
            [entity.chainId]: {
              data: model.data,
              loadMoreUrl: model.loadMoreUrl,
            },
          };
          state.quote = null;
        });

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    async markAsRead(messageId: number) {
      const response = await $api.messenger.markAsRead([messageId]);
      if (response.statusCode === 200) {
        if (this.chain) {
          const messengerStore = useMessengerStore();

          messengerStore.resetUnreadMessagesCount(this.chain.chainId);
          this.chain.unreadMessagesCount = 0;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    async loadMore(url: string, chainId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.getByUrl(url);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        const currentChat = this.chats[chainId];
        currentChat.data = [...model.data, ...currentChat.data];
        currentChat.loadMoreUrl = model.loadMoreUrl;
        this.errors = [];

        this.inAction = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
      return false;
    },
    async createChain(data: RequestChainModel): Promise<number> {
      this.inAction = true;
      const response = await $api.messenger.createChain(data);

      if (response.statusCode === 200) {
        const model = response as ResponseMessageModel;
        this.upsert(model.data);

        this.inAction = false;
        return model.data.chainId;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
      return 0;
    },
    async reply(data: RequestMessageModel): Promise<MessageEntity | undefined> {
      this.inAction = true;
      const response = await $api.messenger.reply(data);

      if (response.statusCode === 200) {
        const model = response as ResponseMessageModel;

        this.upsert(model.data);

        this.inAction = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        if (data.payload !== null) {
          const index = this.chats[data.chainId].data.findIndex(
            ({ payload }) => payload === data.payload
          );

          if (~index) {
            this.chats[data.chainId].data[index].status =
              MessageDeliveryStatusEnum.Error;
          }
        }
        this.errors = error.errorMessages;
      }

      this.inAction = false;
      return undefined;
    },
    async edit(data: EditMessageModel): Promise<void> {
      this.inAction = true;
      const response = await $api.messenger.edit(toEditModel(data));

      if (response.statusCode === 200) {
        this.redact(data);
        this.inAction = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
    },
    setUpdate() {
      this.$patch({
        updated: new Date().getTime(),
      });
    },
    async draft(data: DraftMessageModel): Promise<void> {
      const message: MessageEntity = {
        attachedFiles: {
          count: data.files.length,
          data: data.files,
        },
        authorAlias: data.authorAlias,
        authorAvatarUrl: data.authorAvatarUrl,
        authorFullName: data.authorFullName,
        authorId: data.authorId,
        bodyHtml: data.text,
        chainId: data.chainId,
        createdAt: new Date().toISOString(),
        direction: MessageDirectionTypeEnum.Outgoing,
        editable: false,
        edited: false,
        forwardedAuthorAlias: null,
        forwardedAuthorFullName: null,
        forwardedAttachedFiles: {
          count: data.files.length,
          data: data.files,
        },
        id: 0,
        messageType: MessageTypeEnum.Message,
        originalMessage: data.quoteItem,
        payload: data.payload,
        sharedUserItems: [],
        status: MessageDeliveryStatusEnum.Draft,
        sticker: null,
        uniqueId: '',
      };
      this.upsert(message);
      this.setUpdate();
    },
    async deleteRequest(
      messageId: number,
      type: MessageDeleteEnum,
      chainId: number
    ) {
      this.inAction = true;
      const response =
        type === MessageDeleteEnum.ForMe
          ? await $api.messenger.deleteForMe(messageId)
          : await $api.messenger.deleteForAll(messageId);

      if (response.statusCode === 200) {
        this.delete(messageId, chainId);
        this.inAction = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
    },
    init() {
      this.$patch({
        loading: false,
        errors: [],
        chain: null,
        quote: null,
        isEdit: false,
        chats: {},
      });
    },
    updateQuote(quote: MessageEntity | null, isEdit: boolean) {
      this.$patch(
        cloneDeep({
          quote,
          isEdit,
        })
      );
    },
    upsert(message: MessageEntity) {
      if (!this.chats[message.chainId]) {
        this.chats[message.chainId] = {
          data: [],
          loadMoreUrl: null,
        };
      }

      const chat = this.chats[message.chainId];
      const existingMessage = find(chat.data, { payload: message.payload });

      if (existingMessage) {
        assign(existingMessage, message);
      } else {
        chat.data.push(message);
      }

      this.$patch({
        errors: [],
        quote: null,
        isEdit: false,
      });
    },
    redact(data: EditMessageModel): void {
      const index = this.chats[data.chainId].data.findIndex(
        ({ uniqueId }) => uniqueId === data.uniqueId
      );

      if (~index) {
        const currentMessage = this.chats[data.chainId].data[index];

        const model = cloneDeep(data);

        currentMessage.bodyHtml = model.message.text;
        currentMessage.attachedFiles.data = model.fileInfos;
        currentMessage.attachedFiles.count = model.fileInfos.length;
        currentMessage.edited = true;
      }

      this.$patch({
        errors: [],
        quote: null,
        isEdit: false,
      });
    },
    read(data: MessagesReadModel, all: boolean): void {
      const messengerStore = useMessengerStore();

      //TODO: waiting for improvement - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1465
      if (data.status === MessageDeliveryStatusEnum.Delivered) {
        console.warn('No need to update status to Delivered');
        return;
      }

      const messagesInThisChain = this.chats[data.chainId].data;
      let messagesToRead = messagesInThisChain.filter(
        (m: MessageEntity) =>
          m.chainId === data.chainId &&
          m.authorId === data.authorId &&
          m.status !== data.status
      );
      // console.log('messagesToRead - 1', messagesToRead); //! DEBUG

      if (!all) {
        messagesToRead = messagesInThisChain.filter(
          (m: MessageEntity) => m.uniqueId === data.uniqueId
        );
      }
      // console.log('messagesToRead - 2', messagesToRead); //! DEBUG

      messagesToRead.forEach((message) => {
        message.status = data.status;
      });

      const lastMessage = messagesToRead.at(-1);
      if (!lastMessage) {
        console.warn(
          'Cannot update last message with null/undefined',
          lastMessage
        );
        return;
      }
      messengerStore.updateLastMessage(toLastModel(lastMessage), false);
    },
    delete(messageId: number, chainId: number): MessageEntity {
      this.chats[chainId].data = this.chats[chainId].data.filter(
        ({ id }: MessageEntity) => id !== messageId
      );

      this.$patch({
        errors: [],
        quote: null,
        isEdit: false,
      });

      return this.chats[chainId].data.at(-1) as MessageEntity;
    },
    async updateAvatar(chainId: number, file: File): Promise<boolean> {
      this.errors = [];
      const response = await $api.messenger.updateAvatar(chainId, file);
      if (response.statusCode === 200 && this.chain !== null) {
        const model = response as ResponseChatAvatarModel;
        this.chain.chainAvatar = {
          url: model.data.url ?? null,
          width: model.data.width ?? null,
          height: model.data.height ?? null,
        } as MediaModel;
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteAvatar(chainId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.messenger.deleteAvatar(chainId);
      if (response.statusCode === 200 && this.chain !== null) {
        this.chain.chainAvatar = {
          url: null,
          width: null,
          height: null,
        } as MediaModel;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async chatAddParticipant(
      chainId: number,
      usersIds: number[],
      users: UserEntity[]
    ): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatAddParticipant(
        chainId,
        usersIds
      );

      if (response.statusCode === 200) {
        users.forEach((element) => {
          this.chain?.users.push(toShortUserModel(element));
        });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async chatRemoveParticipant(
      chainId: number,
      usersIds: number[]
    ): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatRemoveParticipant(
        chainId,
        usersIds
      );

      if (response.statusCode === 200) {
        if (this.chain?.users) {
          const index = this.chain.users.findIndex((n) => n.id === usersIds[0]);
          this.chain?.users.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async chatAddAdministrator(
      chainId: number,
      user: UserShortModel
    ): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatAddAdministrator(
        chainId,
        user.id
      );

      if (response.statusCode === 200) {
        this.chain?.adminsIds.push(user.id);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async chatRemoveAdministrator(
      chainId: number,
      userId: number
    ): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatRemoveAdministrator(
        chainId,
        userId
      );

      if (response.statusCode === 200) {
        if (this.chain?.adminsIds) {
          const index = this.chain.adminsIds.findIndex((n) => n === userId);
          this.chain?.adminsIds.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async exitChain(chainId: number, userId: number): Promise<void> {
      if (this.chain !== null && this.chain.chainId === chainId) {
        this.chain.isInChain = false;

        const usersIndex = this.chain.users.findIndex((n) => n.id === userId);
        if (~usersIndex) {
          this.chain?.users.splice(usersIndex, 1);
        }

        const adminsIndex = this.chain.adminsIds.findIndex(
          (n: number) => n === userId
        );
        if (~adminsIndex) {
          this.chain?.adminsIds.splice(adminsIndex, 1);
        }
      }
    },
    async forwardToChain(messageId: number, chainId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.forwardToChain(messageId, chainId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async forwardToUser(messageId: number, userId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.forwardToUser(messageId, userId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
  },
  persist: true,
});

async function getChain(
  chainEntity: MessageChainEntity | null,
  chainId: number
): Promise<MessageChainEntity | ErrorMessageModel[]> {
  let dialog = chainEntity?.chainId === chainId ? chainEntity : null;
  if (dialog === null) {
    const response = await $api.messenger.getChainById(chainId);
    if (response.statusCode === 200) {
      const model = response as ResponseChainModel;
      dialog = cloneDeep(model.data);
      return dialog;
    }

    if (response.statusCode !== 200) {
      const error = response as ResponseErrorModel;
      return error.errorMessages;
    }

    return [] as ErrorMessageModel[];
  } else {
    return dialog;
  }
}
