<template>
  <component
    :is="selectedIcon"
    v-if="selectedIcon !== null"
    v-bind="computedIconProps"
  />
  <ion-icon v-else :icon="documentOutline" />
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { documentOutline } from 'ionicons/icons';
import { computed, ref, watch } from 'vue';
import type { Component, PropType } from 'vue';

import type { AppIconsEnum } from '@/enums';
import { useIcons } from '@/helpers';
import type { IconPropsModel } from '@/types';

// Props
const props = defineProps({
  name: {
    type: String as PropType<AppIconsEnum | string>,
    required: true,
  },
  iconProps: {
    type: Object as PropType<IconPropsModel>,
    default: () => ({}),
  },
  isGitlab: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

// Refs
const selectedIcon = ref<Component | null>(null);

// Computed
const computedIconProps = computed(() => {
  return {
    ...props.iconProps,
  };
});

// Watchers
watch(
  () => props.name,
  async (newName) => {
    const iconName = newName.toLowerCase() as AppIconsEnum;

    selectedIcon.value = await useIcons().loadIcon(iconName, props.isGitlab);
  },
  { immediate: true }
);
</script>
