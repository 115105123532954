import axios from '@/services/axios';
import type {
  DomainModel,
  DomainUpdateModel,
  ResponseDomain,
  ResponseDomains,
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseUsageRules,
  SetUsageRulesPayload,
} from '@/types';

export class AdminApiService {
  async getUsageRules(): Promise<ResponseUsageRules | ResponseErrorModel> {
    return axios.get('/networks/usageRules');
  }
  async setUsageRules(
    payload: SetUsageRulesPayload
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/networks/usageRules', payload);
  }
  async getDomains(
    text?: string
  ): Promise<ResponseDomains | ResponseErrorModel> {
    return axios.get(`/domains?text=${text || ''}&pageSize=50`);
  }
  async createDomain(
    data: DomainModel
  ): Promise<ResponseDomain | ResponseErrorModel> {
    return axios.post(`/domains`, data);
  }
  async saveDomain(
    data: DomainUpdateModel
  ): Promise<ResponseDomain | ResponseErrorModel> {
    return axios.put(`/domains`, data);
  }
  async deleteDomain(
    data: DomainModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/domains/${data.emailSuffix}`);
  }
  async domainsLoadMore(
    url: string
  ): Promise<ResponseDomains | ResponseErrorModel> {
    return axios.get(url);
  }
}
