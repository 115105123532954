import type { Component } from 'vue';
import { markRaw } from 'vue';

import type { AppGitlabIconsEnum, AppIconsEnum } from '@/enums';

interface IconComponent {
  default: Component;
}

export type IIcons = {
  /**
   * Loads an icon by its name.
   * @param {string} AppIconsEnum | AppGitlabIconsEnum - The name of the icon to load.
   * @returns {Promise<Component | null>} - A promise that resolves to the icon component or null if not found.
   */
  loadIcon: (
    iconName: AppIconsEnum | AppGitlabIconsEnum,
    isGitlab: boolean
  ) => Promise<Component | null>;

  /**
   * Get an array of icon file names.
   * @returns {AppIconsEnum |  AppGitlabIconsEnum[]} - An array of icon file names.
   */
  getIconFileNames: () => AppIconsEnum[];
  getGitlabIconFileNames: () => AppGitlabIconsEnum[];
};

let instance: IIcons | null = null;

/**
 * Hook to use icons within the application.
 * @returns {IIcons} - An object with a method to load icons.
 */
export const useIcons = (): IIcons => {
  if (instance) {
    return instance;
  }

  /**
   * Glob import for icons
   * @type {Record<string, () => Promise<{ default: Component }>>}
   * For example: { '../icons/home.vue': () => import('../icons/home.vue') }
   */
  const icons = import.meta.glob('../icons/**/*.vue') as Record<
    string,
    () => Promise<IconComponent>
  >;
  const gitlabIcons = import.meta.glob('../icons_gitlab/**/*.vue') as Record<
    string,
    () => Promise<IconComponent>
  >;

  /**
   * Map of icon names to their respective paths
   * @type {Record<string, string>}
   * For example: { 'home': '../icons/home.vue' }
   */
  const iconMap: Record<string, string> = {};
  const gitlabIconMap: Record<string, string> = {};

  /**
   * Preload all icons/gitlab icons by populating the iconMap/gitlabIconMap with icon names and paths.
   * @returns {Promise<void>}
   */
  const _preloadIcons = async (): Promise<void> => {
    for (const path in icons) {
      const fileName = path.split('/').pop()?.replace('.vue', '').toLowerCase();
      if (fileName) {
        iconMap[fileName] = path;
      } else {
        console.error('Error parsing icon file name:', path);
      }
    }
  };
  const _preloadGitlabIcons = async (): Promise<void> => {
    for (const path in gitlabIcons) {
      const fileName = path.split('/').pop()?.replace('.vue', '').toLowerCase();
      if (fileName) {
        gitlabIconMap[fileName] = path;
      } else {
        console.error('Error parsing icon file name:', path);
      }
    }
  };

  // Office MIME types
  const officeMimeTypes = [
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  // Extensions
  const docExtensions = ['docx', 'doc', 'docm', officeMimeTypes[0]];
  const excelExtensions = ['xlsx', 'xls', 'xlsm', officeMimeTypes[1]];
  const pptExtensions = ['pptx', 'ppt', 'pptm', officeMimeTypes[2]];

  const _isDoc = (ext: string): boolean =>
    docExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  const _isExcel = (ext: string): boolean =>
    excelExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  const _isPpt = (ext: string): boolean =>
    pptExtensions.some((e) => e.toLowerCase() === ext.toLowerCase());

  // Methods for checking extensions
  /**
   * Checks if the extension is an Office extension.
   * @param {string} ext - The file extension to check.
   * @returns {boolean} - True if it is an Office extension, false otherwise.
   */
  const _isOffice = (ext: string): boolean =>
    _isDoc(ext) || _isExcel(ext) || _isPpt(ext);

  // Main function to load the icon
  const loadIcon = async (
    iconName: AppIconsEnum | AppGitlabIconsEnum,
    isGitlab = false
  ): Promise<Component | null> => {
    if (!iconName) {
      console.error('No icon name provided.');
      return null;
    }

    let selectedIcon: Component | null = null;

    // Check if office document icon
    if (_isOffice(iconName)) {
      switch (true) {
        case _isDoc(iconName):
          selectedIcon = markRaw(
            (await import('../icons/FileExtensions/doc.vue')).default
          );
          break;
        case _isExcel(iconName):
          selectedIcon = markRaw(
            (await import('../icons/FileExtensions/xlsx.vue')).default
          );
          break;
        case _isPpt(iconName):
          selectedIcon = markRaw(
            (await import('../icons/FileExtensions/ppt.vue')).default
          );
          break;
        default:
          selectedIcon = null;
          break;
      }
    } else {
      const iconPath = isGitlab ? gitlabIconMap[iconName] : iconMap[iconName];
      if (iconPath) {
        try {
          const iconModule = isGitlab
            ? await gitlabIcons[iconPath]()
            : await icons[iconPath]();
          selectedIcon = markRaw(iconModule.default);
        } catch (error) {
          console.error('Error loading icon:', error);
          selectedIcon = null;
        }
      } else {
        console.error(
          `Icon file not found for the given prop name: ${iconName}`
        );
        selectedIcon = null;
      }
    }

    return selectedIcon;
  };

  const getIconFileNames = (): AppIconsEnum[] => {
    return Object.keys(icons).map(
      (path) => path.split('/').pop()?.replace('.vue', '') || ''
    ) as AppIconsEnum[];
  };

  const getGitlabIconFileNames = (): AppGitlabIconsEnum[] => {
    return Object.keys(gitlabIcons).map(
      (path) => path.split('/').pop()?.replace('.vue', '') || ''
    ) as AppGitlabIconsEnum[];
  };

  /**
   * Preload all icons from src/icons/ directory
   */
  _preloadIcons();

  /**
   * Preload Gitlab icons in development mode
   * This is only necessary in development mode to show all available gitlab icons on /ui-kit/icons/gitlab page
   * In production mode, _preloadGitlabIcons is disabled to reduce app overhead since src/icons_gitlab/ is very large
   * Do not preload gitlab icons in production mode
   * To test gitlab icon in development mode - pass :is-gitlab="true" to src/components/Common/IconsProvider.vue
   * To use gitlab icon in production mode - move it to src/icons/
   */
  if (import.meta.env.DEV) {
    _preloadGitlabIcons();
  }

  instance = {
    loadIcon,
    getIconFileNames,
    getGitlabIconFileNames,
  };

  return instance;
};
