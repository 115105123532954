import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  OfficeConfigRequest,
  OfficeDocumentCreateRequest,
  ResponseOfficeConfig,
  ResponseOfficeFileCreate,
} from '@/types';

export class OfficeApiService {
  async getConfig(
    params: OfficeConfigRequest
  ): Promise<ResponseOfficeConfig | ResponseErrorModel> {
    return axios.get('/files/editorConfig/', { params });
  }

  async create(
    params: OfficeDocumentCreateRequest
  ): Promise<ResponseOfficeFileCreate | ResponseErrorModel> {
    return axios.post('/office/create', params);
  }
}
