<template>
  <div
    v-if="
      milestone && (milestone.dateStart?.length || milestone.dateDue?.length)
    "
    class="milestone-dates"
  >
    <div
      v-if="
        milestone.dateStart?.length === 0 || milestone.dateDue?.length === 0
      "
      class=""
    >
      <div v-if="milestone.dateStart?.length > 0" class="start-date">
        {{
          $t('taskManagement.milestones.dateStart') +
          ': ' +
          formatDateHelper(milestone.dateStart, 'year')
        }}
      </div>

      <div v-else-if="milestone.dateDue?.length > 0" class="end-date">
        {{
          $t('taskManagement.milestones.dateDue') +
          ': ' +
          formatDateHelper(milestone.dateDue, 'year')
        }}
      </div>
    </div>

    <template v-else>
      <div class="start-date">
        {{
          formatDateHelper(
            milestone.dateStart,
            DateHelper.yearsIsEqual(milestone.dateStart, milestone.dateDue)
              ? 'month'
              : 'year'
          )
        }}
      </div>
      <div class="end-date">
        {{
          '-' +
          formatDateHelper(
            milestone.dateDue,
            DateHelper.yearsIsEqual(milestone.dateStart, milestone.dateDue)
              ? 'month'
              : 'year'
          )
        }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { DateHelper, formatDateHelper } from '@/helpers';
import { useProjectsStore } from '@/store';
import type { TaskManagementMilestoneModel } from '@/types';

const props = defineProps({
  milestoneId: {
    type: Number,
    required: true,
  },
});
const projectsStore = useProjectsStore();

const milestone: ComputedRef<TaskManagementMilestoneModel | undefined> =
  computed(() => projectsStore.getMilestoneById(props.milestoneId));
</script>

<style scoped lang="scss">
.milestone-dates {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  color: var(--ion-color-medium);
}
</style>
