<template>
  <app-grid ref="eventRef" class="event-card">
    <app-row>
      <app-col :size="isWidgetView ? '12' : '4'" class="event-card__poster">
        <div class="event-card__poster-body">
          <div class="event-card__poster-header">{{ dateData.month }}</div>
          <div class="event-card__poster-main">{{ dateData.date }}</div>
          <div class="event-card__poster-footer">{{ dateData.weekDay }}</div>
        </div>
      </app-col>
      <app-col class="event-card__main">
        <app-row class="event-card__main-title">
          {{ eventData.title }}
        </app-row>
        <app-row class="event-card__main-description">
          <feed-text :text-data="textData" />
        </app-row>
        <div class="event-card__main-items">
          <app-row v-if="eventData.datetime">
            <app-col
              class="event-card__main-items-header"
              :size="isWidgetView ? '12' : '2'"
              >{{ $t('feed.event.when') }}</app-col
            >
            <app-col :size="isWidgetView ? '12' : '10'">
              {{
                formatDateHelper(eventData.datetime, 'long') +
                ' - ' +
                getEndDate()
              }}
            </app-col>
          </app-row>
          <app-row v-if="eventData.location">
            <app-col
              class="event-card__main-items-header"
              :size="isWidgetView ? '12' : '2'"
              >{{ $t('feed.event.where') }}</app-col
            >
            <app-col :size="isWidgetView ? '12' : '10'">
              <span
                :class="{ 'is-url': locationIsUrl }"
                @click.stop="goToLink(eventData.location)"
                >{{ eventData.location }}</span
              >
            </app-col>
          </app-row>
          <app-row>
            <app-col
              class="event-card__main-items-header"
              :size="isWidgetView ? '12' : '2'"
              >{{ $t('feed.event.who') }}</app-col
            >
            <app-col
              class="event-card__main-username-block"
              :size="isWidgetView ? '12' : '10'"
            >
              <span
                v-for="(user, index) in users"
                :key="user.id"
                :class="{ author: index === users.length - 1 }"
                class="event-card__main-username"
                @click.stop="onUserClick(user)"
              >
                {{ ' ' + user.fullName
                }}<span v-if="index !== users.length - 1" v-html="`&#44;`" />
              </span>
            </app-col>
          </app-row>
        </div>

        <div v-if="!eventData.isBlocker" class="event-card__main-controllers">
          <div
            :class="['event-card__main-question', isWidgetView ? 'fixed' : '']"
          >
            {{ $t('feed.event.attend') }}
            <span
              v-if="eventData?.attendingsCount && eventData.attendingsCount > 0"
              class="event-attendings"
              @click.stop="showAnswers()"
            >
              {{ count }}
            </span>
          </div>
          <ion-button
            :color="
              radioAnswer === FeedEventAnswerEnum.Yes ? 'success' : 'medium'
            "
            :expand="isWidgetView ? 'block' : undefined"
            :fill="
              radioAnswer === FeedEventAnswerEnum.Yes ? 'solid' : 'outline'
            "
            :disabled="previewMode"
            @click.stop="changeAnswer(FeedEventAnswerEnum.Yes)"
          >
            {{ $t('yes') }}
          </ion-button>
          <ion-button
            :color="
              radioAnswer === FeedEventAnswerEnum.Probably
                ? 'warning'
                : 'medium'
            "
            :expand="isWidgetView ? 'block' : undefined"
            :fill="
              radioAnswer === FeedEventAnswerEnum.Probably ? 'solid' : 'outline'
            "
            :disabled="previewMode"
            @click.stop="changeAnswer(FeedEventAnswerEnum.Probably)"
          >
            {{ $t('feed.event.probably') }}
          </ion-button>
          <ion-button
            color="medium"
            :expand="isWidgetView ? 'block' : undefined"
            :fill="radioAnswer === FeedEventAnswerEnum.No ? 'solid' : 'outline'"
            :disabled="previewMode"
            @click.stop="changeAnswer(FeedEventAnswerEnum.No)"
          >
            {{ $t('no') }}
          </ion-button>
        </div>

        <div v-if="eventData.isBlocker" class="event-card__main-controllers">
          <div
            :class="['event-card__main-question', isWidgetView ? 'fixed' : '']"
          >
            {{
              !eventData.currentUserSubscribed
                ? $t('subscribe.subscribeToEvent')
                : $t('subscribe.unsubscribeFromEvent')
            }}
            <span
              v-if="eventData?.blockerSubscribers > 0"
              class="event-attendings"
              @click.stop="showBlockerSubscribers()"
            >
              {{ count }}
            </span>
          </div>
          <ion-button
            :color="eventData.currentUserSubscribed ? 'success' : 'medium'"
            :expand="isWidgetView ? 'block' : undefined"
            :fill="eventData.currentUserSubscribed ? 'solid' : 'outline'"
            :disabled="previewMode || isLoading"
            @click.stop="subscribeToEvent()"
          >
            {{ $t('subscribe.follow') }}
          </ion-button>

          <ion-button
            :expand="isWidgetView ? 'block' : undefined"
            :fill="!eventData.currentUserSubscribed ? 'solid' : 'outline'"
            color="medium"
            :disabled="previewMode || isLoading"
            @click.stop="unsubscribeFromEvent()"
          >
            {{ $t('subscribe.unfollow') }}
          </ion-button>
        </div>
      </app-col>
    </app-row>
  </app-grid>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import { useCssVar, useElementSize } from '@vueuse/core';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { FeedText, AppGrid, AppRow, AppCol } from '@/components';
import type { FeedFlagEnum } from '@/enums';
import { FeedEventAnswerEnum, FeedFilterTypeEnum } from '@/enums';
import {
  componentAnswersShow,
  componentEventSubscribersPopover,
  DateHelper,
  formatDateHelper,
  isValidURL,
  showToast,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { usePostStore } from '@/store';
import type { UserShortModel, PostEventDataModel, PostUser } from '@/types';

const props = defineProps({
  eventData: {
    type: Object as PropType<PostEventDataModel>,
    required: true,
  },
  postId: {
    type: Number,
    required: true,
  },
  textData: {
    type: String,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  parentPostId: {
    type: Number || null,
    default: () => null,
  },
  users: {
    type: Array as PropType<UserShortModel[]>,
    default: () => [],
  },
  author: {
    type: Object as PropType<UserShortModel>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    default: FeedFilterTypeEnum.Recommended,
  },
  previewMode: {
    type: Boolean,
    default: () => false,
  },
});

const { t } = useI18n();
const postStore = usePostStore();
const router = useRouter();
const eventRef = ref(null);
const { width: containerWidth } = useElementSize(eventRef);
const isWidgetView: ComputedRef<boolean> = computed(
  () =>
    containerWidth.value <
    Number(useCssVar('--app-2xs-size').value.slice(0, -2))
);

const isLoading = ref<boolean>(false);

const eventData: ComputedRef<PostEventDataModel> = computed(
  () => props.eventData
);

const count: ComputedRef<string> = computed(
  () =>
    `(${t(
      'feed.event.answers',
      eventData.value.isBlocker
        ? eventData.value.blockerSubscribers
        : eventData.value.attendingsCount
    )})`
);

const radioAnswer = ref<FeedEventAnswerEnum | undefined>(
  eventData.value.answer
);

const postId: ComputedRef<number> = computed(() => props.postId);

const users: ComputedRef<PostUser[]> = computed(() => {
  return [...props.users, props.author].map(({ mainAlias, id, fullName }) => ({
    mainAlias,
    id,
    fullName,
  }));
});

const dateData: ComputedRef<any> = computed(() => ({
  date: DateHelper.getDayOfMonth(props.eventData?.datetime),
  month: formatDateHelper(props.eventData?.datetime, 'monthName'),
  weekDay: formatDateHelper(props.eventData?.datetime, 'week'),
}));

const emit = defineEmits(['onEventDataChange']);
const changeAnswer = async (value: number) => {
  radioAnswer.value = value;
  const data = { id: postId.value, attending: value };
  if (await postStore.eventChangeAnswer(data, props.parentPostId)) {
    await showToast(t('feed.answerSaved'), true);
    emit('onEventDataChange', postId.value, value);
  } else {
    await showToast(t('feed.answerNotSaved'), true);
  }
};

const locationIsUrl: ComputedRef<boolean> = computed(() => {
  return isValidURL(eventData.value.location);
});

const goToLink = (link: string) => {
  if (locationIsUrl.value) {
    if (link.startsWith('https://') || link.startsWith('http://')) {
      window.open(link, '_blank');
    } else {
      window.open('https://' + link, '_blank');
    }
  }
};

const onUserClick = async (user: PostUser) => {
  await router.push({
    name: ROUTES_NAME.USER_BY_ID,
    params: { id: user.id },
  });
};

const getEndDate = (): string => {
  if (props.eventData !== null) {
    const date = DateHelper.addMinutesToDateInISO(
      props.eventData.datetime,
      props.eventData.durationMinutes
    );
    if (props.eventData.durationMinutes > 60) {
      return formatDateHelper(date, 'long');
    } else {
      return formatDateHelper(date, 'day');
    }
  }
  return '';
};

const showAnswers = async () => {
  await componentAnswersShow(props.postId, eventData.value.answer);
};

const subscribeToEvent = async () => {
  if (eventData.value.currentUserSubscribed) return;
  isLoading.value = true;
  if (
    await postStore.eventSubscriptionChange(
      props.postId,
      true,
      props.parentPostId
    )
  ) {
    await showToast(t('subscribe.subscribedToEvent'), true);
  }
  isLoading.value = false;
};

const unsubscribeFromEvent = async () => {
  if (!eventData.value.currentUserSubscribed) return;

  isLoading.value = true;
  if (
    await postStore.eventSubscriptionChange(
      props.postId,
      false,
      props.parentPostId
    )
  ) {
    await showToast(t('subscribe.unsubscribedFromEvent'), true);
  }
  isLoading.value = false;
};

const showBlockerSubscribers = async () => {
  await componentEventSubscribersPopover(props.postId);
};
</script>

<style scoped lang="scss">
.event-card {
  border: 1px solid var(--ion-color-custom-element-darker);
  border-radius: app-radius(md);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  margin-top: 0.5rem;
  --ion-grid-columns: 12;
  overflow: hidden;
}

.event-card__poster {
  text-align: center;
  padding: app-padding(lg);
  background: var(--ion-color-custom-element-darker);
  display: flex;
}

.event-card__poster-body {
  width: 70px;
  margin: auto;
  height: auto;
  border-radius: app-radius(md);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.event-card__poster-header {
  font-size: 0.75rem;
  font-weight: 700;
  background: var(--ion-color-primary);
  border-top-left-radius: inherit;
  color: var(--ion-color-custom);
  border-top-right-radius: inherit;
}

.event-card__poster-main {
  font-size: 200%;
  font-weight: 700;
  color: var(--ion-background-color);
}

.event-card__poster-footer {
  border-top: 1px dashed var(--ion-color-medium);
  font-weight: 700;
  font-size: 0.75rem;
  margin: 0 12px 5px 12px;
  color: var(--ion-background-color);
}

.event-card__main {
  padding: app-padding(lg);
}

.event-card__main-title {
  font-size: 1.225rem;
}

.event-card__main-description {
  font-size: 0.875rem;
  margin: app-padding(lg) 0;
}

.event-card__main-items {
  font-size: 0.9rem;
}

.event-card__main-items-header {
  padding-left: 0;
  color: var(--ion-color-medium);
  margin-top: 2px;
}

.event-card__main-controllers {
  margin-top: app-padding(lg);
  font-size: 0.9rem;

  ion-button {
    @include resetStyleFromIonicButton;
    --border-radius: #{app-radius(md)};
    margin-bottom: app-padding(md);
    margin-right: 4px;
    &.button-block {
      margin-inline: unset;
    }
    &.unfollow {
      --background: var(--ion-color-light-custom);
      --color: var(--ion-color-medium);
    }
  }
}

.event-card__main-question {
  margin-bottom: app-padding(md);
  line-height: 1rem;
  &.fixed {
    min-height: 2rem;
  }
}

.event-attendings {
  text-decoration: underline;
  user-select: none;
}

.event-attendings:hover {
  cursor: pointer;
  opacity: 0.7;
}

.event-card__main-username-block {
  display: inline;
}

.is-url,
.event-card__main-username {
  color: var(--ion-color-primary);
  user-select: none;
  text-decoration: underline;
}

.is-url:hover,
.event-card__main-username:hover {
  cursor: pointer;
  opacity: 0.7;
}

@include respond-to-min-width(2xl) {
  .event-card__main-items {
    font-size: 1rem;
  }
  .event-card__main-controllers {
    font-size: 1rem;
  }
}
</style>
