import { GroupsTypeEnum } from '@/enums';
import type {
  GroupEntity,
  MediaModel,
  GroupInformation,
  GroupsIdsModel,
  GroupsIdsUserModel,
  UserGroupShortModel,
} from '@/types';

export const defaultGroup: GroupEntity = {
  accessType: 0,
  adminIds: [],
  createdAt: '',
  createdByUserID: 0,
  description: '',
  id: 0,
  isMandant: false,
  isOfficial: false,
  mainAlias: '',
  avatar: {
    url: '' || null,
    width: 0 || null,
    height: 0 || null,
  } as MediaModel,
  cover: {
    url: '' || null,
    width: 0 || null,
    height: 0 || null,
  } as MediaModel,
  information: {
    text: '',
  } as GroupInformation,
  stats: { members: 0, messages: 0 },
  title: '',
  type: GroupsTypeEnum.Public,
  url: '',
  webUrl: '',
  showMembers: true,
  showInformation: true,
  showDocs: true,
  enableLikes: true,
  enableComments: true,
  enableShared: true,
};

export const defaultShortGroup: UserGroupShortModel = {
  id: 0,
  mainAlias: '',
  title: '',
  type: GroupsTypeEnum.Public,
  //TODO: LQIP_V2 - wait for model update
  avatar: {
    url: '' || null,
    width: 0 || null,
    height: 0 || null,
  } as MediaModel,
  //TODO: LQIP_V2 - remove this after
  urlPhoto: '' || null,
  urlPhotoX2: '' || null,
  urlPhotoMax: '' || null,
};

export const defaultGroupsIds: GroupsIdsModel = {
  all: {
    loadMoreUrl: null,
    ids: [],
  },
  search: {
    loadMoreUrl: null,
    ids: [],
  },
  byUser: [] as GroupsIdsUserModel[],
  canPost: {
    loadMoreUrl: null,
    ids: [],
  },
  isAdmin: {
    loadMoreUrl: null,
    ids: [],
  },
};
