import { cloneDeep, orderBy, sample } from 'lodash';
import { defineStore } from 'pinia';

import { OrgChartTypeEnum } from '@/enums';
import { defaultNetwork } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import type {
  RequestAuthNetworkUser,
  ErrorMessageModel,
  NetworkEntity,
  NetworkLocateModel,
  NetworkFullSettingsModel,
  ResponseErrorModel,
  ResponseNetworksModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkLinkModel,
  ResponseNetworkModel,
} from '@/types';

interface NetworkState extends EntityState<NetworkEntity> {
  settings: NetworkFullSettingsModel | null;
  network: NetworkEntity;
  settingNetwork: boolean;
  isNetworkAvailable: boolean;
}

export const useNetworkStore = defineStore({
  id: 'network',
  state: (): NetworkState => ({
    data: [],
    errors: [],
    loading: false,
    settings: null,
    network: cloneDeep(defaultNetwork),
    settingNetwork: false,
    isNetworkAvailable: true,
  }),
  getters: {
    autocomplete:
      (state) =>
      (text: string): NetworkEntity[] => {
        text = text.toLowerCase();
        return text.length > 0
          ? state.data.filter((n) => n.title.toLowerCase().includes(text))
          : state.data;
      },
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    orgChartType: (state): OrgChartTypeEnum =>
      state.settings?.orgChartType ?? OrgChartTypeEnum.Off,
    getNetwork: (state): NetworkEntity => state.network,
    getCurrentSettings: (state): NetworkFullSettingsModel | null =>
      state.settings,
    getNetworks: (state) => {
      return state.data;
    },
    getRandomNetwork: (state): NetworkEntity | undefined => {
      return sample(state.data);
    },
    getNetworkById:
      (state) =>
      (id: string): NetworkEntity | undefined => {
        const index = state.data.findIndex(
          (network: NetworkEntity) => network.id === id
        );

        if (~index) {
          return state.data[index];
        }
        return undefined;
      },
  },
  actions: {
    async networksByUri(clientSecret: string): Promise<string | undefined> {
      const response = await $api.auth.oauth(clientSecret);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkLinkModel;

        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async networksByForm(
      data: RequestAuthNetworkUser
    ): Promise<NetworkEntity[] | undefined> {
      this.$patch({
        data: [],
        loading: true,
        errors: [],
      });
      const response = await $api.auth.networks(data);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworksModel;

        const networks = orderBy(
          model.data,
          ['isOfficial', 'isHome'],
          ['desc', 'desc']
        );
        this.data = networks;

        this.loading = false;
        return networks;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async networksByUser(): Promise<NetworkEntity[] | undefined> {
      this.$patch({
        loading: true,
        errors: [],
      });
      const response = await $api.user.networks();

      if (response.statusCode === 200) {
        const model = response as ResponseNetworksModel;

        const networks = orderBy(
          model.data,
          ['isOfficial', 'isHome'],
          ['desc', 'desc']
        );
        this.data = networks;

        this.loading = false;
        return networks;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async networkForUser(clientSecret: string): Promise<boolean> {
      this.$patch({
        network: cloneDeep(defaultNetwork),
        loading: true,
        errors: [],
      });
      const response = await $api.network.current(clientSecret);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkModel;

        this.network = cloneDeep(model.data);
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
    async checkSettings(
      uri: string,
      clientSecret: string
    ): Promise<NetworkLocateModel | undefined> {
      const settings = await $api.network.getLocate(uri, clientSecret);

      if (settings) {
        this.errors = [];
        return cloneDeep(settings);
      }
      return undefined;
    },
    async getSettings(
      clientSecret: string
    ): Promise<NetworkFullSettingsModel | undefined> {
      this.loading = true;
      const response = await $api.network.settings(clientSecret);
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;

        this.settings = cloneDeep(model.data);

        this.isNetworkAvailable = true;
        this.loading = false;
        return this.settings;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    updateNotificationsCount(count: number) {
      this.network.notificationCount = count;
      const index = this.data.findIndex((n) => n.id === this.network.id);
      if (~index) {
        this.data[index].notificationCount = count;
      }
    },
    async uploadLogo(key: string, type: string): Promise<string | null> {
      const response = await $api.network.uploadLogo(key, type);
      if (response.statusCode === 200) {
        //const model = response as NetworkUploadLogoResponseModel;
        return `${key}.${type}`;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return null;
    },
    async uploadFavicon(key: string, type: string): Promise<string | null> {
      const response = await $api.network.uploadFavicon(key, type);
      if (response.statusCode === 200) {
        //const model = response as NetworkUploadFaviconResponseModel;
        return `${key}.${type}`;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return null;
    },
  },
  persist: true,
});
