import axios from '@/services/axios';
import type {
  SupportModel,
  ResponseErrorModel,
  ResponseSuccessModel,
} from '@/types';

export class ToolApiService {
  async sendToSupport(
    params: SupportModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/tools/sendToSupport', params);
  }
}
