import { $api, axios } from '@/services';
import type {
  RequestMessageModel,
  ResponseChainsModel,
  ResponseMessagesModel,
  ResponseErrorModel,
  ResponseMessageModel,
  RequestChainModel,
  ResponseSuccessModel,
  RequestEditMessageModel,
  ResponseUnreadModel,
  ResponseChatAvatarModel,
  ResponseChainModel,
  ResponseFileModel,
} from '@/types';

export class MessengerApiService {
  async getChains(): Promise<ResponseChainsModel | ResponseErrorModel> {
    return axios.get('/messages/all');
  }
  async getChainById(
    chainId: number
  ): Promise<ResponseChainModel | ResponseErrorModel> {
    return axios.get(`/messages/chainById/${chainId}`);
  }

  async getByUserId(
    userId: number
  ): Promise<ResponseMessagesModel | ResponseErrorModel> {
    return axios.get(`/messages/byUserId/${userId}`);
  }

  async getMessagesByChainId(
    chainId: number
  ): Promise<ResponseMessagesModel | ResponseErrorModel> {
    return axios.get(`/messages/byChainId/${chainId}`);
  }

  async getByUrl(
    url: string
  ): Promise<ResponseMessagesModel | ResponseChainsModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async getUnread(
    messageId: number | null
  ): Promise<ResponseUnreadModel | ResponseErrorModel> {
    const params = messageId !== null ? `?lastId=${messageId}` : '';
    return axios.get(`/messages/unread/${params}`);
  }

  async createChain(
    data: RequestChainModel
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post(`/messages/create`, data);
  }

  async reply(
    data: RequestMessageModel
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post(`/messages/reply`, data);
  }

  async forwardToChain(
    messageId: number,
    chainId: number
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post(`/messages/forwardToChain`, { messageId, chainId });
  }

  async forwardToUser(
    messageId: number,
    userId: number
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post(`/messages/forwardToUser`, { messageId, userId });
  }

  async edit(
    data: RequestEditMessageModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/messages/edit`, data);
  }

  async markAsRead(
    ids: number[]
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/messages/markAsRead`, ids);
  }

  async markAsUnread(
    id: number
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post(`/messages/markAsUnread`, { id });
  }

  async userTyping(
    chainId: number,
    typing: boolean
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post(`/messages/typing`, { chainId, typing });
  }

  async deleteForMe(
    messageId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/messages/deleteForMe/${messageId}`);
  }

  async deleteForAll(
    messageId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/messages/deleteForAll/${messageId}`);
  }

  async moveToArchive(
    chainId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/messages/moveToArchive/${chainId}`);
  }

  async moveToActive(
    chainId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/messages/moveToActive/${chainId}`);
  }

  async muteChainChange(
    chainId: number,
    mute: boolean
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/messages/muteChain', {
      chainId: chainId,
      mute: mute,
    });
  }

  async deleteChain(
    chainId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/messages/deleteChain/${chainId}`);
  }

  async exitChain(
    chainId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/messages/exitChain/${chainId}`);
  }

  async renameChain(
    chainId: number,
    chainTitle: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/messages/renameChain', { chainId, chainTitle });
  }

  async updateAvatar(
    chainId: number,
    file: File
  ): Promise<ResponseChatAvatarModel | ResponseErrorModel> {
    const response = await $api.file.upload(file);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post(`/messages/uploadChainAvatar/${chainId}`, {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }

  async deleteAvatar(
    chainId: number
  ): Promise<ResponseChatAvatarModel | ResponseErrorModel> {
    return axios.delete(`/messages/deleteChainAvatar/${chainId}`);
  }

  async chatAddParticipant(
    chainId: number,
    usersIds: number[]
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post('/messages/addParticipant', { chainId, usersIds });
  }

  async chatRemoveParticipant(
    chainId: number,
    usersIds: number[]
  ): Promise<ResponseMessageModel | ResponseErrorModel> {
    return axios.post('/messages/removeParticipant', { chainId, usersIds });
  }

  async chatAddAdministrator(
    chainId: number,
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/messages/chainAddAdmin', { userId, chainId });
  }

  async chatRemoveAdministrator(
    chainId: number,
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/messages/chainRemoveAdmin', { userId, chainId });
  }
}
