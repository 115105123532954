<template>
  <ion-button
    mode="md"
    size="default"
    fill="clear"
    class="menu"
    @click.stop="openPostMenu($event, post)"
  >
    <!-- TODO: Place the icon in icons-provider when it is available -->
    <ion-icon
      v-if="feedFlag === FeedFlagEnum.SharedPost"
      slot="icon-only"
      :icon="icons.open"
    />
    <icons-provider
      v-else
      slot="icon-only"
      :icon-props="{ width: '22', height: '22' }"
      name="menuDots"
    />
  </ion-button>
</template>

<script lang="ts" setup>
import { Clipboard } from '@capacitor/clipboard';
import { IonIcon, IonButton } from '@ionic/vue';
import { openOutline } from 'ionicons/icons';
import type { PropType } from 'vue';
import 'swiper/css';
import { useRouter } from 'vue-router';

import { IconsProvider } from '@/components';
import {
  FeedFlagEnum,
  FeedTypeEnum,
  PostMenuActionEnum,
  FeedFilterTypeEnum,
  ShareArchiveLinkType,
  DocumentExtensionEnum,
  FileStatusEnum,
  PostMenuOptionsFlagEnum,
} from '@/enums';
import {
  componentAppDatePickerPopover,
  DateHelper,
  postContextMenu,
  postContextMenuSheet,
  showToast,
  componentPostEdit,
  componentSendPostToExternalEmail,
  componentPostComplain,
  isNativeMobile,
  htmlToText,
  isAnyMobile,
  componentShareArchiveLink,
  filesHybrid,
  isBlob,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { usePostStore } from '@/store';
import type { PostEntity } from '@/types';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const icons = {
  open: openOutline,
};
const { t } = useI18n();
const postStore = usePostStore();

const props = defineProps({
  post: {
    type: Object as PropType<PostEntity>,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedFilterTypeEnum | null>,
    default: FeedFilterTypeEnum.Recommended,
  },
});

const clipboard = Clipboard;

const router = useRouter();

const switchActions = async (
  ev: Event,
  result: PostMenuActionEnum | undefined,
  postData: PostEntity
) => {
  switch (result) {
    case PostMenuActionEnum.Share:
      {
        const innerResult = await postContextMenuSheet(
          postData,
          FeedFlagEnum.ConversationPage,
          FeedTypeEnum.Announcement,
          PostMenuOptionsFlagEnum.Share
        );
        await switchActions(ev, innerResult.data, postData);
      }
      break;

    case PostMenuActionEnum.Download:
      {
        const innerResult = await postContextMenuSheet(
          postData,
          FeedFlagEnum.ConversationPage,
          FeedTypeEnum.Announcement,
          PostMenuOptionsFlagEnum.Download
        );
        await switchActions(ev, innerResult.data, postData);
      }
      break;
    case PostMenuActionEnum.Open:
      await openPost(postData.id);
      break;
    case PostMenuActionEnum.Delete:
      if (
        !postData.plannedPostData
          ? await postStore.postDelete(postData.id)
          : await postStore.switchPlannedDelete(postData)
      ) {
        if (props.feedFlag === FeedFlagEnum.ConversationPage) {
          await router.push({
            name: ROUTES_NAME.FEED,
          });
        }
      } else {
        await showToast(
          t('feed.conversationPostMenu.delete.postNotDeleted'),
          false
        );
      }
      break;

    case PostMenuActionEnum.AddToBookmarks:
      await addToBookmarks(postData.id);
      break;

    case PostMenuActionEnum.RemoveFromBookmarks:
      await removeFromBookmarks(postData.id);
      break;

    case PostMenuActionEnum.Pin:
      if (await postStore.pinPost(postData.id)) {
        await showToast(t('feed.conversationPostMenu.attach.postPinned'), true);
      } else {
        await showToast(
          t('feed.conversationPostMenu.attach.postNotPinned'),
          false
        );
      }
      break;

    case PostMenuActionEnum.UnPin:
      if (await postStore.unPinPost(postData.id)) {
        await showToast(
          t('feed.conversationPostMenu.attach.postUnpinned'),
          true
        );
      } else {
        await showToast(
          t('feed.conversationPostMenu.attach.postNotUnpinned'),
          false
        );
      }
      break;

    case PostMenuActionEnum.Follow:
      if (await postStore.followToPost(postData.id)) {
        await showToast(
          t('feed.conversationPostMenu.following.followed'),
          true
        );
      } else {
        await showToast(
          t('feed.conversationPostMenu.following.notFollowed'),
          false
        );
      }
      break;

    case PostMenuActionEnum.UnFollow:
      if (await postStore.unFollowFromPost(postData.id)) {
        await showToast(
          t('feed.conversationPostMenu.following.unfollowed'),
          true
        );
      } else {
        await showToast(
          t('feed.conversationPostMenu.following.notUnfollowed'),
          false
        );
      }
      break;

    case PostMenuActionEnum.Edit:
      await componentPostEdit(postData);
      break;

    case PostMenuActionEnum.SendToEmail:
      if (await postStore.sendPostToEmail(postData.id)) {
        await showToast(t('feed.conversationPostMenu.send.mailSend'), true);
      } else {
        await showToast(t('feed.conversationPostMenu.send.mailNotSend'), false);
      }
      break;

    case PostMenuActionEnum.SendToExternalEmail:
      await componentSendPostToExternalEmail(postData.id);
      break;

    case PostMenuActionEnum.ShareArchiveLink:
      {
        await componentShareArchiveLink(
          postData.id,
          ShareArchiveLinkType.UserItem
        );
      }
      break;

    case PostMenuActionEnum.DownloadAsPDF:
      {
        const response = await postStore.postDownload(
          postData.messageType,
          DocumentExtensionEnum.PDF,
          postData.id
        );
        if (isBlob(response)) {
          const status = await filesHybrid.downloadPost(
            postData,
            response as Blob
          );
          await showToast(
            t('files.successDownloaded'),
            status === FileStatusEnum.Success
          );
        }
      }
      break;

    case PostMenuActionEnum.DownloadAsZIP:
      {
        const response = await postStore.postDownload(
          postData.messageType,
          DocumentExtensionEnum.ZIP,
          postData.id
        );
        if (isBlob(response)) {
          const status = await filesHybrid.downloadPost(
            postData,
            response as Blob
          );
          await showToast(
            t('files.successDownloaded'),
            status === FileStatusEnum.Success
          );
        }
      }
      break;

    case PostMenuActionEnum.Complain:
      await componentPostComplain(postData.id);
      break;

    case PostMenuActionEnum.CopyText: {
      let text = '';
      if (props.post.messageType === FeedTypeEnum.Idea && props.post.ideaData) {
        text = htmlToText(
          props.post.ideaData.title +
            ` ${t('feed.idea.problem')}: ` +
            props.post.ideaData.problem +
            ` ${t('feed.idea.solution')}: ` +
            props.post.ideaData.solution
        );
      } else {
        text = htmlToText(props.post.bodyHtml);
      }
      if (isNativeMobile) {
        await clipboard.write({
          string: text,
        });
      } else {
        await navigator.clipboard.writeText(text);
      }

      await showToast(t('appPopoverMenu.copy.textCopied'), true);
      break;
    }

    case PostMenuActionEnum.SendNow:
      await postStore.switchPlannedForce(postData);
      break;
    case PostMenuActionEnum.ReSchedule:
      {
        const result = await componentAppDatePickerPopover(
          ev,
          DateHelper.addMinutesToDateInISO(new Date().toISOString(), 15),
          true,
          true,
          5
        );
        if (
          postData.plannedPostData &&
          result.data &&
          !DateHelper.isDateInPast(result.data)
        ) {
          postData.plannedPostData.plannedDate = result.data;
          await postStore.switchPlannedUpdate(postData);
        } else if (result.data && DateHelper.isDateInPast(result.data)) {
          await showToast(t('validations.pastDate'), false);
        }
      }
      break;
  }
};

const openPostMenu = async (ev: Event, postData: PostEntity) => {
  if (props.feedFlag === FeedFlagEnum.SharedPost) {
    return await openPost(postData.id);
  }

  const isAnnouncement =
    props.feedType === FeedFilterTypeEnum.Announcement
      ? FeedFilterTypeEnum.Announcement
      : '';
  let result;
  if (isAnyMobile) {
    result = await postContextMenuSheet(
      postData,
      FeedFlagEnum.ConversationPage,
      isAnnouncement
    );
  } else {
    result = await postContextMenu(postData, props.feedFlag, isAnnouncement);
  }

  await switchActions(ev, result.data, postData);
};

const addToBookmarks = async (id: number) => {
  if (await postStore.addBookmark(id)) {
    await showToast(
      t('feed.conversationPostMenu.bookmark.bookmarkAdded'),
      true
    );
  } else {
    await showToast(
      t('feed.conversationPostMenu.bookmark.bookmarkNotAdd'),
      false
    );
  }
};

const removeFromBookmarks = async (id: number) => {
  if (await postStore.removeBookmark(id)) {
    await showToast(
      t('feed.conversationPostMenu.bookmark.bookmarkRemoved'),
      true
    );
  } else {
    await showToast(
      t('feed.conversationPostMenu.bookmark.bookmarkNotRemoved'),
      false
    );
  }
};

const openPost = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.POST_BY_ID,
    params: { id: id },
  });
};
</script>

<style scoped lang="scss">
ion-button {
  &.menu {
    @include resetStyleFromIonicButton;
    --color: var(--ion-color-medium);
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --padding-end: 0;
    --padding-start: 0;
    margin: 0;
    z-index: 1;

    &:hover {
      ion-icon {
        opacity: 0.7;
      }
    }
  }
}
</style>
