<template>
  <user-avatar
    slot="start"
    :fio="userData.fullName"
    :url="userData.avatar?.url ?? ''"
    :image="userData.avatar"
    :user-id="userData.id"
    @click="$emit('onAction')"
  />
  <ion-label :class="['ion-text-wrap', size]" @click="$emit('onAction')">
    {{ userData.fullName }}
    <p v-if="withAlias && userData.mainAlias.length > 0">
      {{ userData.mainAlias }}
    </p>
    <p v-if="withSubtitle && subtitle.length > 0">
      {{ subtitle }}
    </p>
    <user-contributions
      v-if="userData.contributions && withContributions"
      :contributions="userData.contributions"
      :user-id="user.id"
      :size="size"
    />
  </ion-label>
  <slot />
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { UserAvatar, UserContributions } from '@/components';
import { useUsersHelper } from '@/helpers';
import type { UserDataModel, UserEntity, UserShortModel } from '@/types';

// Props
const props = defineProps({
  user: {
    type: Object as PropType<UserEntity | UserShortModel>,
    required: true,
  },
  withSubtitle: {
    type: Boolean,
    default: () => true,
  },
  withAlias: {
    type: Boolean,
    default: () => false,
  },
  withContributions: {
    type: Boolean,
    default: () => false,
  },
  size: {
    type: String as PropType<'small' | 'default'>,
    default: () => 'default',
  },
});

// Other
const usersHelper = useUsersHelper();

// Computed
const userData: ComputedRef<UserDataModel> = computed(() => getUserData());
const subtitle: ComputedRef<string> = computed(() => {
  return usersHelper.getUserSubtitle(
    userData.value.position,
    userData.value.subDivisionTitle
  );
});

// Actions
const getUserData = (): UserDataModel => {
  return {
    id: props.user.id,
    fullName: props.user.fullName,
    subDivisionTitle:
      'subDivisionTitle' in props.user ? props.user.subDivisionTitle : '',
    position: 'position' in props.user ? props.user.position : '',
    mainAlias: 'mainAlias' in props.user ? props.user.mainAlias : '',
    avatar:
      'avatar' in props.user
        ? props.user.avatar
        : 'image' in props.user
          ? props.user.image
          : null,
    contributions:
      'contributions' in props.user ? props.user.contributions : null,
  };
};

defineEmits(['onAction']);
</script>
<style scoped lang="scss">
ion-label {
  font-size: 1rem;
  p {
    word-break: normal;
  }
  &.small {
    font-size: 0.8rem;
    line-height: 1;
    p {
      font-size: 0.7rem;
    }
  }
}
</style>
