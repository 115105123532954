<template>
  <div :class="{ block: text !== '' }">
    <form-input
      v-if="selectedUser === null"
      v-model:value="text"
      type="text"
      :placeholder="placeholder"
      custom-size="medium"
      :on-behalf-custom="true"
      :is-single-item="true"
    >
    </form-input>
    <div v-if="text !== '' && users.length !== 0" class="list-block">
      <ion-list class="list" mode="md">
        <ion-list-header v-if="users.length > 0" lines="none">
          <ion-label>{{ $t('appMenu.people') }}</ion-label>
        </ion-list-header>

        <ion-item
          v-for="item in users"
          :key="`user_${item.id}`"
          @click="onUserChoose(item)"
        >
          <user-list-item :user="item" with-subtitle />
        </ion-item>
      </ion-list>
    </div>
    <div v-if="selectedUser" class="chip-block">
      <ion-chip outline @click="deleteUser">
        <ion-label>{{ selectedUser.fullName }}</ion-label>
        <ion-icon :icon="icons.close" />
      </ion-chip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  IonIcon,
  IonLabel,
  IonChip,
  IonItem,
  IonList,
  IonListHeader,
} from '@ionic/vue';
import { closeOutline, atOutline } from 'ionicons/icons';
import debounce from 'lodash/debounce';
import type { ComputedRef, PropType } from 'vue';
import { ref, watch, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { FormInput, UserListItem } from '@/components';
import { UsersFilterEnum, GroupsTypeEnum, UserRoleEnum } from '@/enums';
import { toShortUserModel } from '@/helpers';
import { ROUTES_NAME } from '@/router';
import { useGroupsStore, useUserStore } from '@/store';
import type { UserEntity, GroupEntity, UserShortModel } from '@/types';

const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
  author: {
    type: Object as PropType<UserShortModel | null>,
    default: () => null,
  },
});

const icons = {
  close: closeOutline,
  at: atOutline,
};

const route = useRoute();
const userStore = useUserStore();
const groupStore = useGroupsStore();

const text = ref<string>('');
const selectedUser = ref<UserShortModel | null>(null);

const currentUserId: ComputedRef<number> = computed(
  () => userStore.current?.id ?? 0
);

const currentUserRoleId: ComputedRef<number> = computed(
  () => userStore.current?.roleId ?? 0
);

const isGroupAdmin: ComputedRef<boolean> = computed(
  () =>
    groupData.value.adminIds.includes(currentUserId.value) ||
    currentUserRoleId.value >= UserRoleEnum.SuperAdministrator
);

const currentGroupId = computed(() =>
  route.name === ROUTES_NAME.GROUP_BY_ID ? Number(route.params.id) : 0
);

const groupData: ComputedRef<GroupEntity> = computed(() =>
  groupStore.getGroupById(currentGroupId.value)
);

const groupParticipants: ComputedRef<UserEntity[]> = computed(
  () => userStore.getUsersFromGroupId(currentGroupId.value).data
);

const users: ComputedRef<UserEntity[]> = computed(() => {
  const result = userStore.getUsersChosen(UsersFilterEnum.ChosenOthers).data;
  if (
    currentGroupId.value !== 0 &&
    groupData.value.type === GroupsTypeEnum.PrivateHidden &&
    !isGroupAdmin.value
  ) {
    const filteredUsers = result.filter((user) =>
      groupParticipants.value.map((u) => u.id).includes(user.id)
    );
    return filteredUsers;
  }
  return result;
});

//Bitoobit. This is debounce search.
const debounceSearch = debounce(async (text) => {
  await userStore.chooseUserAutocomplete(
    text,
    UsersFilterEnum.ChosenOthers,
    true
  );
}, 500);

watch(text, async () => {
  if (text.value !== '') {
    debounceSearch(text.value);
  }
});

const emit = defineEmits(['onChange']);

function isUserEntity(user: any): user is UserEntity {
  return (user as UserEntity).url !== undefined;
}

const onUserChoose = (user: UserEntity | UserShortModel) => {
  const isCurrentUserId = currentUserId.value === user.id;

  if (!isCurrentUserId) {
    selectedUser.value = isUserEntity(user) ? toShortUserModel(user) : user;
    emit('onChange', selectedUser.value);
  }

  text.value = '';
  userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenOthers);
};

const deleteUser = () => {
  selectedUser.value = null;
  emit('onChange', selectedUser.value);
};

onMounted(() => {
  if (props.author) {
    const isCurrentUserId = currentUserId.value === props.author.id;

    if (!isCurrentUserId) {
      selectedUser.value = props.author;
    }

    text.value = '';
    userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenOthers);
  }
});
</script>

<style scoped lang="scss">
.block {
  width: 100%;
}
.chip-block {
  margin-left: 1rem;
  height: 100%;
  ion-chip {
    border-radius: app-radius(md);
    margin: 0;
    height: 100%;
    border: 1px solid var(--ion-color-light-tint);

    ion-icon {
      color: var(--ion-color-dark);
    }
  }
}

.list-block {
  border: 2px solid var(--ion-color-custom-element-lighter);
  border-radius: 0 0 #{app-radius(md)} #{app-radius(md)};
  border-top: none;
  position: relative;
  bottom: 0.8rem;
  overflow: hidden;
  z-index: 1;
  background: var(--ion-color-light-background-contrast);
}
.list {
  max-height: 210px;
  overflow-y: scroll;
  padding-inline: 1rem;
  margin: 0;
  background: var(--ion-color-light-background-contrast);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--ion-color-custom-element-lighter);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--ion-color-custom-element-darker);
  }

  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --background: rgba(255, 255, 255, 0);

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  ion-list-header {
    padding-inline-start: 0;
    text-transform: uppercase;
    text-align: center;

    ion-label {
      background: var(--ion-color-custom-element-lighter);
      padding: app-padding(md);
      border-radius: app-radius(md);
      color: var(--ion-color-medium);
    }
  }
}

ion-icon.email {
  font-size: 40px;
}
</style>
