import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { defineStore } from 'pinia';

import type { EntityState } from '@/store';
import type { FileCacheModel } from '@/types';

type FileState = EntityState<FileCacheModel>;
export const FILES_DATA_STORAGE = 'files';

export const useFileStore = defineStore({
  id: 'files',
  state: (): FileState => ({
    data: [],
    errors: [],
    loading: false,
  }),
  actions: {
    async init() {
      if (Capacitor.isNativePlatform()) {
        this.$reset();
        try {
          const filesList = await Preferences.get({ key: FILES_DATA_STORAGE });
          this.data = filesList.value ? JSON.parse(filesList.value) : [];
        } catch (e: any) {
          this.errors = [e.message];
        }
      }
    },
    add(file: FileCacheModel) {
      this.data = [file, ...this.data];
    },
  },
  persist: true,
});
