import { defineStore } from 'pinia';

import type { SearchUserItemTypes } from '@/enums';
import { SearchType } from '@/enums';
import { serializeQueryParams, splitStringToNumberArr } from '@/helpers';
import { $api } from '@/services';
import {
  usePostStore,
  useDocStore,
  useGroupsStore,
  useUserStore,
  // useWikiStore,
  useTopicStore,
} from '@/store';
import type { ResponseSearchModel } from '@/types';

interface SearchStore {
  isLoading: boolean;
  accuracy: number;
  suggestIsLoading: boolean;
  data: any[];
  suggestions: string[];
}

export const useSearchStore = defineStore({
  id: 'search',

  state: (): SearchStore => ({
    isLoading: false,
    accuracy: 0.01,
    suggestIsLoading: false,
    data: [],
    suggestions: [],
  }),

  getters: {
    getData: (state) => state.data,
  },

  actions: {
    _updateFromSearch(data: any[], type: SearchType | undefined) {
      const postStore = usePostStore();
      const docStore = useDocStore();
      const groupsStore = useGroupsStore();
      const userStore = useUserStore();
      // const wikiStore = useWikiStore();
      const topicsStore = useTopicStore();

      switch (type) {
        case SearchType.Posts:
          postStore.postsFromSearch(data);
          break;
        case SearchType.Files:
          docStore.docsFromSearch(data);
          break;
        case SearchType.Groups:
          groupsStore.groupsFromSearch(data);
          break;
        case SearchType.Users:
          userStore.usersFromSearch(data);
          break;
        // case SearchType.Wiki:
        //   wikiStore.wikisFromSearch(data);
        //   break;
        case SearchType.Tags:
          topicsStore.topicsFromSearch(data);
          break;
        default:
          this.data = data;
      }
    },
    async search(payload: {
      searchText?: string;
      type?: SearchType;
      postType?: SearchUserItemTypes;
      authorsIds?: string;
      start?: string;
      end?: string;
      groupsIds?: string;
      tagsIds?: string;
    }): Promise<boolean> {
      try {
        this.isLoading = true;
        const {
          searchText,
          type,
          postType,
          authorsIds,
          tagsIds,
          start,
          end,
          groupsIds,
        } = payload;

        const authorsIdsArr = authorsIds
          ? splitStringToNumberArr(authorsIds, '*')
          : undefined;
        const tagsIdsArr = tagsIds
          ? splitStringToNumberArr(tagsIds, '*')
          : undefined;
        const groupsIdsArr = groupsIds
          ? splitStringToNumberArr(groupsIds, '*')
          : undefined;

        const queryObj = {
          filter: type,
          AuthorsIds: authorsIdsArr,
          GroupsIds: groupsIdsArr,
          TagsIds: tagsIdsArr,
          ...(searchText && { searchText }),
          ...(postType && { postType }),
          ...(start && { start }),
          ...(end && { end }),
        };
        const queryString = serializeQueryParams(queryObj);
        const response = await $api.search.search(queryString);

        if (response.statusCode === 200) {
          const model = response as ResponseSearchModel;
          if (model.data?.entities) {
            this._updateFromSearch(model.data.entities, payload.type);
          }
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Failed to search by query', error);
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async suggest(payload: {
      text?: string;
      type?: SearchType;
      postType?: SearchUserItemTypes;
    }): Promise<boolean> {
      try {
        this.suggestIsLoading = true;

        const response = await $api.search.suggest({
          filter: payload.type,
          postType: payload.postType,
          query: payload.text,
          accuracy: this.accuracy,
        });

        if (response.statusCode === 200) {
          const model = response as ResponseSearchModel;
          this.suggestions = model.data.suggestions ?? [];
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Failed to suggest by query', error);
        return false;
      } finally {
        this.suggestIsLoading = false;
      }
    },
  },

  persist: true,
});
