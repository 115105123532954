import type {
  TaskManagementMilestonesSortByEnum,
  TaskManagementProjectsSortByEnum,
  TaskManagementSortDirectionEnum,
  TaskManagementTasksSortByEnum,
} from '@/enums';
import axios from '@/services/axios';
import type {
  RequestTasksSearchByColumnIdModel,
  ResponseTaskManagementBoardModel,
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseProjectModel,
  ResponseProjectsModel,
  TaskManagementCreateTaskRequestModel,
  ResponseMilestonesModel,
  ResponseMilestoneModel,
  ResponseTasksModel,
  ResponseTaskModel,
  TaskManagementCreateMilestoneRequestModel,
  TaskManagementUpdateMilestoneRequestModel,
  RequestTasksBySearchModel,
  ResponseTaskCreateDataModel,
  RequestTaskCommentCreateModel,
  ResponseTaskCommentCreateModel,
  ResponseTasksByColumnModel,
} from '@/types';

export class ProjectsApiService {
  async getProjectsAll(
    sort: TaskManagementProjectsSortByEnum,
    direction: TaskManagementSortDirectionEnum
  ): Promise<ResponseProjectsModel | ResponseErrorModel> {
    return axios.get(`/projects/all?sort=${sort}&sortDirection=${direction}`);
  }

  async getProjectById(
    projectId: number
  ): Promise<ResponseProjectModel | ResponseErrorModel> {
    return axios.get(`/projects/byId/${projectId}`);
  }

  async getProjectsByGroupId(
    groupId: number,
    sort: TaskManagementProjectsSortByEnum,
    direction: TaskManagementSortDirectionEnum
  ): Promise<ResponseProjectsModel | ResponseErrorModel> {
    return axios.get(
      `/projects/byGroupId?groupId=${groupId}&sort=${sort}&sortDirection=${direction}`
    );
  }

  async removeProject(
    projectId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/projects/delete/${projectId}`);
  }

  async setProjectAssignee(
    projectId: number,
    assigneeId: number
  ): Promise<any | ResponseErrorModel> {
    return axios.post(`/projects/setAssignee/${projectId}`, { assigneeId });
  }

  async createNewProject(
    title: string,
    groupId: number
  ): Promise<ResponseProjectModel | ResponseErrorModel> {
    return axios.post(`/projects/create`, { title, groupId });
  }

  async setProjectTitle(
    projectId: number,
    title: string
  ): Promise<ResponseProjectModel | ResponseErrorModel> {
    return axios.post(`/projects/setTitle/${projectId}?title=${title}`);
  }

  async getTasksByProjectId(
    projectId: number,
    direction: TaskManagementSortDirectionEnum,
    sort: TaskManagementTasksSortByEnum
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(
      `/tasks/all?projectId=${projectId}&sort=${sort}&sortDirection=${direction}`
    );
  }

  async getTasksByAuthorId(
    authorId: number,
    direction: TaskManagementSortDirectionEnum,
    sort: TaskManagementTasksSortByEnum
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(
      `/tasks/all?authorId=${authorId}&sort=${sort}&sortDirection=${direction}`
    );
  }

  async getTasksBySearchByAuthorId(
    search: string,
    authorId: number,
    sortDirection: TaskManagementSortDirectionEnum,
    sort: TaskManagementTasksSortByEnum
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(`/tasks/all?authorId=${authorId}`, {
      params: { search, sort, sortDirection },
    });
  }

  async getTasksByAssigneeId(
    assigneeId: number,
    direction: TaskManagementSortDirectionEnum,
    sort: TaskManagementTasksSortByEnum
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(
      `/tasks/all?assigneeId=${assigneeId}&sort=${sort}&sortDirection=${direction}`
    );
  }

  async getTasksBySearchByAssigneeId(
    search: string,
    assigneeId: number,
    sortDirection: TaskManagementSortDirectionEnum,
    sort: TaskManagementTasksSortByEnum
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(`/tasks/all?assigneeId=${assigneeId}`, {
      params: { search, sort, sortDirection },
    });
  }

  async getTasksByColumnId(
    columnId: number
  ): Promise<ResponseTasksByColumnModel | ResponseErrorModel> {
    return axios.get(`/tasks/byBoardColumnId?boardColumnId=${columnId}`);
  }

  async getTasksByColumnIdLoadMoreURL(
    url: string
  ): Promise<ResponseTasksByColumnModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async deleteColumn(
    columnId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/boards/deleteColumn/${columnId}`);
  }

  async getTaskById(
    taskId: number
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.get(`/tasks/byId/${taskId}`);
  }

  async taskCreate(
    taskData: TaskManagementCreateTaskRequestModel
  ): Promise<ResponseTaskCreateDataModel | ResponseErrorModel> {
    return axios.post('/tasks/create', taskData);
  }

  async taskUpdateTitle(
    taskId: number,
    title: string
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/setTitle/${taskId}?title=${title}`);
  }

  async taskClose(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/close/${taskId}`);
  }

  async taskOpen(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/open/${taskId}`);
  }

  async taskArchivate(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/archivate/${taskId}`);
  }

  async taskDearchivate(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/dearchivate/${taskId}`);
  }

  async taskEnableNotifications(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/enableNotification/${taskId}`);
  }

  async taskDisableNotifications(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/disableNotification/${taskId}`);
  }

  async updateTaskDueDate(
    taskId: number,
    dateDue?: string
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/setDateDue/${taskId}?dateDue=${dateDue}`);
  }

  async taskSetAssignee(
    taskId: number,
    assigneeId: number
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/setAssignee/${taskId}?assigneeId=${assigneeId}`);
  }

  async taskRemoveAssignee(
    taskId: number
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/setAssignee/${taskId}`);
  }

  async taskSetMilestone(
    taskId: number,
    milestoneId: number
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(
      `/tasks/setMilestone/${taskId}?milestoneId=${milestoneId}`
    );
  }

  async taskRemoveMilestone(
    taskId: number
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/setMilestone/${taskId}`);
  }

  async getTasksBySearch(
    query: RequestTasksBySearchModel
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(`/tasks/all/`, { params: query });
  }

  async taskAddTags(
    taskId: number,
    tagsIds: number[]
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/addTags`, { id: taskId, tagsIds });
  }

  async taskRemoveTags(
    taskId: number,
    tagsIds: number[]
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/removeTags`, { id: taskId, tagsIds });
  }

  async taskUpdateDescription(
    taskId: number,
    description: string
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post('/tasks/setDescription/', { id: taskId, description });
  }

  async deleteTask(
    taskId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/tasks/delete/${taskId}`);
  }

  async getMilestonesBySearch(
    searchQuery: string,
    projectId: number,
    sort: TaskManagementMilestonesSortByEnum,
    direction: TaskManagementSortDirectionEnum
  ): Promise<ResponseMilestonesModel | ResponseErrorModel> {
    return axios.get(
      `/milestones/byProjectId?projectId=${projectId}&sort=${sort}&sortDirection=${direction}&search=${searchQuery}`
    );
  }

  async getMilestoneById(
    milestoneId: number
  ): Promise<ResponseMilestoneModel | ResponseErrorModel> {
    return axios.get(`/milestones/byId/${milestoneId}`);
  }

  async createNewMilestone(
    milestoneData: TaskManagementCreateMilestoneRequestModel
  ): Promise<any | ResponseErrorModel> {
    return axios.post(`/milestones/create`, milestoneData);
  }

  async updateMilestoneById(
    milestoneData: TaskManagementUpdateMilestoneRequestModel
  ): Promise<any | ResponseErrorModel> {
    return axios.post(`/milestones/update`, milestoneData);
  }

  async deleteMilestone(
    milestoneId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/milestones/delete/${milestoneId}`);
  }

  async getMilestonesByProjectId(
    projectId: number,
    sort: TaskManagementMilestonesSortByEnum,
    direction: TaskManagementSortDirectionEnum
  ): Promise<ResponseMilestonesModel | ResponseErrorModel> {
    return axios.get(
      `/milestones/byProjectId?projectId=${projectId}&sort=${sort}&sortDirection=${direction}`
    );
  }

  async getMilestonesByLoadMoreURL(
    url: string
  ): Promise<ResponseMilestonesModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async getTasksByLoadMoreURL(
    url: string
  ): Promise<ResponseTasksModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async getProjectsByLoadMoreURL(
    url: string
  ): Promise<ResponseProjectsModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async getProjectsBySearch(
    searchQuery: string,
    sort: TaskManagementProjectsSortByEnum,
    direction: TaskManagementSortDirectionEnum,
    groupId?: number | undefined
  ): Promise<ResponseProjectsModel | ResponseErrorModel> {
    return groupId
      ? axios.get(
          `/projects/byGroupId?groupId=${groupId}&search=${searchQuery}&sort=${sort}&sortDirection=${direction}`
        )
      : axios.get(
          `/projects/all?search=${searchQuery}&sort=${sort}&sortDirection=${direction}`
        );
  }

  async taskAddParticipant(
    taskId: number,
    participantsIds: number[]
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/addParticipants`, {
      id: taskId,
      participantsIds,
    });
  }

  async taskRemoveParticipant(
    taskId: number,
    participantsIds: number[]
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/removeParticipants`, {
      id: taskId,
      participantsIds,
    });
  }

  async taskAddFiles(
    taskId: number,
    fileExistIds: string[],
    fileTempIds: string[]
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/addFiles`, {
      id: taskId,
      fileExistIds,
      fileTempIds,
    });
  }

  async taskRemoveFiles(
    taskId: number,
    filesIds: number[]
  ): Promise<ResponseTaskModel | ResponseErrorModel> {
    return axios.post(`/tasks/removeFiles`, {
      id: taskId,
      filesIds,
    });
  }

  async taskCommentCreate(
    comment: RequestTaskCommentCreateModel
  ): Promise<ResponseTaskCommentCreateModel | ResponseErrorModel> {
    return axios.post(`/tasksComments/create`, comment);
  }

  async taskCommentDelete(
    commentId: number
  ): Promise<ResponseTaskCommentCreateModel | ResponseErrorModel> {
    return axios.delete(`/tasksComments/delete/${commentId}`);
  }

  async getBoardById(
    boardId: number
  ): Promise<ResponseTaskManagementBoardModel | ResponseErrorModel> {
    return axios.get(`/boards/byId/${boardId}`);
  }

  async createColumn(
    boardId: number,
    tagId: number
  ): Promise<ResponseTaskManagementBoardModel | ResponseErrorModel> {
    return axios.post(`/boards/createColumn`, { boardId, tagId });
  }

  async moveColumn(
    boardColumnId: number,
    index: number
  ): Promise<ResponseTaskManagementBoardModel | ResponseErrorModel> {
    return axios.post(`/boards/move`, { boardColumnId, index });
  }

  async moveTask(
    taskId: number,
    boardId: number,
    fromBoardColumnId: number,
    toBoardColumnId: number,
    afterTaskId: number | null,
    beforeTaskId: number | null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tasks/move`, {
      taskId,
      boardId,
      fromBoardColumnId,
      toBoardColumnId,
      afterTaskId,
      beforeTaskId,
    });
  }

  async getTasksSearchByColumnId(
    query: RequestTasksSearchByColumnIdModel
  ): Promise<ResponseTasksByColumnModel | ResponseErrorModel> {
    return axios.get(`/tasks/byBoardColumnId/`, { params: query });
  }
}
